import { pedantMarketApi } from "../clients"
import { type PassportDataSavingData, type PassportDataVerifiingData, type PassportDataGettingData } from "../types"

const get = (subscriptionOrderId: string) => {
  return pedantMarketApi.get<PassportDataGettingData>(`/customer-passports/get-by-subscription-order/${subscriptionOrderId}`)
}

const save = (data: PassportDataSavingData) => {
  return pedantMarketApi.post("/customer-passports", data)
}

const verify = (data: PassportDataVerifiingData) => {
  return pedantMarketApi.post("/customer-passports/verify", data)
}

export const customerPassports = {
  get,
  save,
  verify
}
