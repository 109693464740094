<script setup lang="ts">
import { ref, computed } from "vue"
import { cx } from "cva"
import { Modal, SearchInput } from "shared/ui"
import { storeToRefs } from "pinia"
import { useCitiesStore } from "stores"
import groupBy from "lodash/groupBy"
import { splitToChunks } from "shared/lib"
import { type City } from "shared/api"
import { useBreakpoints } from "@vueuse/core"

const citiesStore = useCitiesStore()
const { list: cities } = storeToRefs(citiesStore)

const breakpoints = useBreakpoints({ md: 768 })
const mdAndLarger  = breakpoints.greaterOrEqual("md")

const searchText = ref("")

const groupCitiesByLetter = (cities: City[]) => {
  return groupBy(cities, (city: City) => {
    return city.name.charAt(0)
  })
}

const filteredCities = computed((): City[] => {
  if (!cities) {
    return []
  }

  return cities.value.filter((city: City) => {
    return city.name.toLowerCase().startsWith(searchText.value.toLowerCase().trimEnd())
  })
})

const columns = computed(() => {
  if (!filteredCities.value) {
    return
  }

  return splitToChunks(filteredCities.value, 3)
})

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "changeCity"): void
  // eslint-disable-next-line no-unused-vars
  (e: "closed"): void
}>()

const changeCity = (city: City) => {
  searchText.value = ""
  citiesStore.changeCurrent(city.name)
  emit("changeCity")
}

const groupStyles = cx([
  "relative",
  "grid",
  "gap-[3px]",
  "mb-5",
  "last:mb-0"
])

const letterStyles = cx([
  "absolute",
  "text-[18px]/[27px]",
  "font-bold",
  "text-yellow"
])

const cityStyles = cx([
  "pl-[25px]",
  "text-[18px]/[27px]",
  "font-medium",
  "hover:text-accent-hover",
  "active:text-accent-click",
  "hover:font-semibold",
  "transition",
  "duration-300"
])
</script>

<template>
  <Modal
    :has-logo="true"
    content-transition="vfm-slide-up"
    :to-bottom="!mdAndLarger"
    :min-height="90"
    class-name="min-h-[90vh] sm:min-h-[auto]"
    @closed="emit('closed')"
  >
    <div class="min-w-full p-4 lg:min-w-[820px] lg-xl:min-w-[1016px] md:pr-[30px] pb-5">
      <SearchInput
        v-model="searchText"
        class="mb-[15px]"
        placeholder="Поиск..."
        icon-align="left"
      />
      <div
        v-if="filteredCities.length"
        class="grid grid-cols-1 md:grid-cols-3 sm:max-h-[450px] overflow-y-scroll"
      >
        <div
          v-for="(column, index) in columns"
          :key="'column' + index"
        >
          <div
            v-for="(group, letter) in groupCitiesByLetter(column)"
            :key="'group' + letter"
            :class="groupStyles"
          >
            <span :class="letterStyles">
              {{ letter }}
            </span>
            <a
              v-for="(city, cityIndex) in group"
              :key="'city' + cityIndex"
              href="#"
              :class="cityStyles"
              @click.prevent="changeCity(city)"
            >
              {{ city.name }}
            </a>
          </div>
        </div>
      </div>
      <div v-else>
        Ничего не найдено
      </div>
    </div>
  </Modal>
</template>
