<script setup lang="ts">
import { ref, watch, onMounted } from "vue"
import classes from "./classes"
import {
  Location,
  MainMenu,
  IconButtons,
  ModalShowServiceCenters,
  ModalShowServiceCenter,
  ModalShowCities,
  ModalConfirmCity
} from "widgets"
import { SearchInput, Logo } from "shared/ui"
import { ModalsContainer, useModal } from "vue-final-modal"
import { useCitiesStore, useServiceCentersStore } from "stores"
import { storeToRefs } from "pinia"

const serviceCentersStore = useServiceCentersStore()
const citiesStore = useCitiesStore()

const { hasConfirm: hasConfirmCity, current: currentCity } = storeToRefs(citiesStore)
const searchText = ref("")
const displayMobileSearchInput = ref(false)

const toggleSearch = () => {
  displayMobileSearchInput.value = !displayMobileSearchInput.value
}

/*--------- MODALS START ---------*/
const { open: openModalShowServiceCenters, close: closeModalShowServiceCenters } = useModal({
  component: ModalShowServiceCenters,
  attrs: {
    onServiceCenterSelected() {
      closeModalShowServiceCenters()
      openModalShowServiceCenter()
    },
    onShowCities() {
      closeModalShowServiceCenters()
      openModalShowCities()
    }
  }
})

const { open: openModalShowServiceCenter } = useModal({
  component: ModalShowServiceCenter
})

const { open: openModalShowCities, close: closeModalShowCities } = useModal({
  component: ModalShowCities,
  attrs: {
    onChangeCity() {
      citiesStore.confirm()
      closeModalShowCities()
    },
    onClosed() {
      citiesStore.confirm()
    }
  }
})

const { open: openModalConfirmCity, close: closeModalConfirmCity } = useModal({
  component: ModalConfirmCity,
  attrs: {
    onConfirm() {
      citiesStore.confirm()
      closeModalConfirmCity()
    },
    onChangeCity() {
      closeModalConfirmCity()
      openModalShowCities()
    },
    onClosed() {
      citiesStore.confirm()
    }
  }
})
/*--------- MODALS END ---------*/

onMounted(() => {
  if (!hasConfirmCity.value) {
    openModalConfirmCity()
  }

  serviceCentersStore.getList()
})

watch(
  currentCity,
  (newCurrentCity, oldCurrentCity) => {
    if (oldCurrentCity?.id !== newCurrentCity?.id) {
      serviceCentersStore.getList()
    }
  },
  { deep: true }
)
</script>

<template>
  <header
    id="header"
    :class="classes.headerStyles"
  >
    <div :class="classes.headerInnerStyles">
      <div
        v-if="!displayMobileSearchInput"
        class="container mx-auto flex items-center justify-between"
      >
        <Logo />
        <Location
          :city="currentCity"
          @click="openModalShowServiceCenters"
        />
        <div class="grid grid-flow-col gap-x-10 items-center">
          <SearchInput
            v-model="searchText"
            class="hidden lg:block lg:min-w-[415px] lg-xl:min-w-[445px] min-h-[44px]"
            placeholder="Искать iPhone 12"
          />
          <IconButtons @handle-click="toggleSearch" />
        </div>
      </div>
      <SearchInput
        v-else
        v-model="searchText"
        class="container mx-auto"
        placeholder="Искать iPhone 12"
        closable
        @close="toggleSearch"
      />
    </div>
    <MainMenu />
    <ModalsContainer />
  </header>
</template>
