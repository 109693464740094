const wrapper = [
  "pb-6",
  "grid",
  "grid-areas-product-sm",
  "md:grid-areas-product-md",
  "md:grid-cols-[447px_auto_300px]",
  "md:gap-x-[35px]"
]

const header = [
  "grid-in-header",
  "flex",
  "flex-col",
  "md:flex-row",
  "md:items-center",
  "md:justify-between"
]

const title = [
  "!mb-1.5",
  "md:!mb-6"
]

const vendorCode = [
  "text-ghost",
  "mb-6",
  "md:mb-0"
]

const sliderWrapper = [
  "grid-in-slider-wrapper",
  "-mr-4",
  "z-10",
  "lg:mr-0"
]

const infoWrapper = "grid-in-info-wrapper"

const actionWrapper = [
  "grid-in-action-wrapper",
  "grid",
  "gap-6",
  "content-start"
]

export default {
  wrapper,
  header,
  title,
  vendorCode,
  sliderWrapper,
  infoWrapper,
  actionWrapper
}
