const wrapper = [
  "flex",
  "md:mb-14"
]

const image = [
  "w-[104px]",
  "h-[138px]",
  "rounded-xl",
  "mr-3.5",
  "md:mr-6",
  "md:w-[230px]",
  "md:h-[309px]"
]

const deviceName = [
  "font-bold",
  "text-primary",
  "leading-[18px]",
  "mb-3.5",
  "md:text-[36px]/[43px]",
  "md:mb-4"
]

const price = [
  "grid",
  "grid-rows-[repeat(2,_auto)]",
  "md:grid-rows-auto",
  "md:grid-cols-[repeat(2,_min-content)]",
  "md:whitespace-nowrap",
  "md:items-center",
  "md:gap-[7px]",
  "md:text-[24px]/[31px]",
  "md:my-4"
]

const title = [
  "text-[16px]/[11px]",
  "font-bold",
  "text-primary",
  "md:text-[24px]"
]

const pricePayment = [
  "text-[32px]",
  "font-bold",
  "text-primary",
  "md:text-[24px]"
]

const pricePaymentSpan = [
  "text-[24px]"
]

const content = "flex-1"

export default {
  wrapper,
  image,
  deviceName,
  price,
  title,
  pricePayment,
  pricePaymentSpan,
  content
}
