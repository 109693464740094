const wrapper = [
  "max-h-[360px]",
  "overflow-x-scroll",
  "p-2",
  "rounded-md",
  "border-2",
  "border-solid",
  "border-border-primary"
]

const item = [
  "relative",
  "flex",
  "items-start",
  "rounded-md",
  "py-3",
  "px-2",
  "transition",
  "duration-300",
  "md:py-3",
  "md:pr-4",
  "md:pl-2",
  "hover:bg-bg-main-thirdly"
]

const itemActive = "bg-bg-main-thirdly"

const image = [
  "hidden",
  "rounded",
  "w-[88px]",
  "h-[68px]",
  "mr-3.5",
  "md:block"
]

const address = [
  "font-bold",
  "text-primary",
  "mb-1"
]

const workTimes = [
  "text-[12px]",
  "text-primary",
  "md:text-[14px]"
]

const button = [
  "relative",
  "ml-auto",
  "!text-ghost",
  "z-[2]"
]

const radio = [
  "mr-3",
  "md:mr-6"
]

const content = [
  "md:flex",
  "md:justify-between",
  "md:w-full",
  "md:items-start"
]

export default {
  wrapper,
  item,
  itemActive,
  image,
  address,
  workTimes,
  button,
  radio,
  content
}
