import _ from "lodash"
import api, { Device, SubscriptionPercent } from "shared/api"
import { LocationQuery, NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import { useCatalogDeviceStore, useSubscriptionStore, useUserStore } from "stores"
import { Ref } from "vue"
import { ROUTE_NAMES } from "shared/constants"
import { AxiosError } from "axios"
import { router } from "app/providers"
import mixpanel from "mixpanel-browser"
import {emitYandexMetrika, YANDEX_METRIKA_GOALS} from "shared/lib/yandex-metrika"
import { storeToRefs } from "pinia"

export function splitToChunks<T>(array: T[], parts: number) {
  const arrayCopy = _.cloneDeep(array)
  const result = [] as T[][]
  for(let i = parts; i > 0; i--) {
    result.push(arrayCopy.splice(0, Math.ceil(arrayCopy.length / i)))
  }
  return result
}

export function declension(number: number, wordVariants: string[]) {
  if (number > 100) number = number % 100
  if (number <= 20 && number >= 10) return wordVariants[2]
  if (number > 20) number = number % 10
  return number === 1 ? wordVariants[0] : number > 1 && number < 5 ? wordVariants[1] : wordVariants[2]
}

export function numberSpace(n: number | string) {
  let parsed: number
  if(typeof n === "string") {
    parsed = parseFloat(n)
  } else {
    parsed = n
  }
  n = Math.round(parsed)
  const str = String(n)
  const reversedArr = str.split("").reverse()
  const arr = []
  for(let i = 0; i < reversedArr.length; i++) {
    arr.push(reversedArr[i])
    if((i + 1) % 3 === 0) {
      arr.push(" ")
    }
  }
  if(arr[arr.length - 1] === " ") {
    arr.pop()
  }
  return arr.reverse().join("")
}

export const redirectToFileIfPossible = (currentRoute: RouteLocationNormalized) => {
  const routeFileNames = [
    "ofertaPM", "politics", "oferta_podpiska", "oferta_vikup", "oferta_service"
  ]

  const currentRouteFileName = routeFileNames.find(routeFileName => `/${routeFileName}` === currentRoute.path)
  if (currentRouteFileName) {
    window.location.href = `${window.location.protocol}//${window.location.host}/${currentRouteFileName}.pdf`
    return true
  }

  return
}

export const redirectToPersonalCabinetIfPossible  = (currentRoute: RouteLocationNormalized) => {
  if (currentRoute.name !== ROUTE_NAMES.ACCOUNT) return

  window.location.href = "https://lk.pedant.market"

  return true
}

export const redirectToHomeIfPossible  = (currentRoute: RouteLocationNormalized, next: NavigationGuardNext) => {
  const subscriptionStore = useSubscriptionStore()

  const isSubscriptionPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION
  const isSubscriptionFormPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION_FORM
  const isSubscriptionFormFilledPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION_FORM_FILLED
  const subscriptionOrderId = currentRoute.params.subscriptionOrderId

  if ((isSubscriptionPage && !subscriptionStore.device) ||
      (isSubscriptionFormPage && !subscriptionStore.device) ||
      (isSubscriptionFormFilledPage && !subscriptionOrderId && !subscriptionStore.device)) {
    next("/")
    return true
  }

  return false
}

// загружаем паспортные данные для страницы /subscription/{ORDER_ID} и редиректим, если устройства уже нет в наличии
export const redirectToOutOfStockIfPossible = async (currentRoute: RouteLocationNormalized, next: NavigationGuardNext) => {
  const isSubscriptionFormFilledPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION_FORM_FILLED
  const subscriptionOrderId = currentRoute.params.subscriptionOrderId

  if (!(isSubscriptionFormFilledPage && subscriptionOrderId && typeof subscriptionOrderId === "string")) return

  const subscriptionStore = useSubscriptionStore()

  try {
    await subscriptionStore.getPassportData(subscriptionOrderId)
      .then(() => {
        subscriptionStore.getOrder()
        subscriptionStore.getDevice()
          .catch((error: Error) => {
            if (error instanceof Error && "isAxiosError" in error) {
              const e = error as AxiosError<{ errors?: Record<string, string[]> }>
              if (e.response?.status === 404) {
                next({ name: ROUTE_NAMES.SUBSCRIPTION_OUT_OF_STOCK })
                throw error
              }
            }
          })
      })
  } catch {
    router.push(`/subscription-order-accepted/${subscriptionOrderId}`)
  }

  return false
}

export const redirectTo404IfCatalogDeviceNotFound = async (currentRoute: RouteLocationNormalized, next: NavigationGuardNext) => {
  const isCatalogDevicePage = currentRoute.name === ROUTE_NAMES.CATALOG_DEVICE
  if (!(isCatalogDevicePage && currentRoute.params.id && typeof currentRoute.params.id === "string")) return

  const catalogDeviceStore = useCatalogDeviceStore()

  try {
    await catalogDeviceStore.getItem(currentRoute.params.id)
      .catch((error: Error) => {
        if ("isAxiosError" in error) {
          const e = error as AxiosError<{ errors?: Record<string, string[]> }>
          if (e.response?.status === 404) {
            emitYandexMetrika(YANDEX_METRIKA_GOALS.PAGE_NOT_FOUND)
            mixpanel.track("page_not_found", {
              from: currentRoute.fullPath
            })

            next({ name: ROUTE_NAMES.NOT_FOUND })
            throw error
          }
        }
      })
  } catch {
    return true
  }

  return false
}

export const redirectIfShortLinkFound = async (currentRoute: RouteLocationNormalized) => {
  if (currentRoute.name !== ROUTE_NAMES.REDIRECT) return
  if (!currentRoute.params.slug && currentRoute.params.slug !== "string") return

  try {
    const { data } = await api.redirects.get(currentRoute.params.slug as string)
    if (data.To) {
      if (data.To.includes("http")) {
        window.location.href = data.To
        return
      }
      window.location.href = data.To
      return
    }
    window.location.href = "/"
  } catch (error) {
    window.location.href = "/"
  }

  return true
}

export const redirectIfSigningLinkFound = async (currentRoute: RouteLocationNormalized) => {
  if (currentRoute.name !== ROUTE_NAMES.SIGNING) return
  if (!currentRoute.params.slug && currentRoute.params.slug !== "string") return

  window.location.href = `https://lk.pedant.market/s/${currentRoute.params.slug}`

  return true
}

export const redirectToPaymentIfPossible = async (currentRoute: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (currentRoute.name !== ROUTE_NAMES.PAYMENT_REDIRECT && currentRoute.name !== ROUTE_NAMES.ADVANCED_PAYMENT_REDIRECT) return
  if (!currentRoute.params.slug && currentRoute.params.slug !== "string") return

  try {
    const { data } = await api.paymentRedirect.get(
        currentRoute.params.slug as string,
        currentRoute.name === ROUTE_NAMES.ADVANCED_PAYMENT_REDIRECT
    )
    if (data.paymentLink) {
      window.location.href = data.paymentLink
    } else {
      next("/")
    }
  } catch (error) {
    next("/")
  }

  return true
}

export const getCatalogDeviceColorsAndMemoriesIfPossible = () => {
  const catalogDeviceStore = useCatalogDeviceStore()
  const { item: device} = storeToRefs(catalogDeviceStore)

  if (!device.value) return

  catalogDeviceStore.getColor({
    model_group: device.value.ParentId,
    models: [device.value.ModelId]
  })?.then(color => {
    if (!color) return

    catalogDeviceStore.selectedColor = color
  })

  catalogDeviceStore.getMemory({
    model_group: device.value.ParentId,
    models: [device.value.ModelId]
  })?.then(memory => {
    if (!memory) return

    catalogDeviceStore.selectedMemory = memory
  })
}

export const getNextSundayDate = () => {
  const today = new Date()
  const currentDayOfWeek = today.getDay()
  const daysUntilSunday = 7 - currentDayOfWeek
  const nextSunday = new Date(today.getTime() + daysUntilSunday * 24 * 60 * 60 * 1000)

  return nextSunday.toLocaleString("ru-RU", { day: "numeric", month: "long" })
}

export const formatCurrency = (num: number) => {
  return num.toLocaleString("ru-RU", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

export const getModelColorFancyString = (device: Device | null | undefined) => {
  if (!device) return

  return device.Color.Name.replace(" ", String.fromCharCode(160))
}

export const getModelMemoryFancyString = (device: Device | null | undefined) => {
  if (!device) return

  return device.ModelMemory.Name.replace(" ", String.fromCharCode(160))
}

export const getMonthlyPayment = (device: Device | null | undefined) => {
  if (!device) return

  const basicPrice = device.BasicPrice
  if (!basicPrice) return

  return basicPrice * 0.088
}

export const getSubscriptionMonthlyPayment = (device: Device | null | undefined, subscriptionPercent: SubscriptionPercent | null | undefined) => {
  if (!device || !subscriptionPercent) return

  const basicPrice = device.BasicPrice
  if (!basicPrice) return

  return basicPrice * subscriptionPercent.Percent
}

export const checkIsNew = (device: Device | null | undefined) => {
  if (!device) return

  return device.ModelCondition.Name === "Новый"
}

export const checkIsIPhone = (device: Device | null | undefined) => {
  if (!device) return

  return device.ParentName === "iPhone"
}

export const checkIsViewed = (device: Device | null | undefined) => {
  if (!device) return

  const userStore = useUserStore()

  return userStore.viewedDevicesIds.includes(device.Id)
}

export const getSim = (device: Device | null | undefined) => {
  if (!device) return

  const countSim = device.Attributes.find(item => item.id === "524da8e0-8e28-11ec-ba52-9126374cabbe")
  const formatSim = device.Attributes.find(item => item.id === "5e8fc840-8e28-11ec-ba52-9126374cabbe")

  return countSim && formatSim ? `${countSim.value} ${formatSim.value}` : null
}

export const getMinCreditPrice = (device: Device | null | undefined) => {
  if (!device) return

  const finalPaymentAmount = Math.ceil((device.Price * 8) / 100)
  return Math.round((device.Price + finalPaymentAmount) / 12)
}

export const loadDeviceImage = (device: Device, isLoadingImage: Ref<boolean>, imageSrc: Ref<string>) => {
  const image = new Image()

  image.src = device.WebpPhotos?.length > 0 ? device.WebpPhotos[0]?.Url : ""
  image.onload = () => {
    isLoadingImage.value = false
    imageSrc.value = image.src
  }
  image.onerror = function() {
    image.src = device.Photos?.length > 0 ? device.Photos[0]?.Url : ""
    image.onload = () => {
      isLoadingImage.value = false
      imageSrc.value = image.src
    }
  }
}

export const parseUtmTags = (routerQuery: LocationQuery) => {
  const utmObj = <{ [key: string]: string; }>({})
  const UTM_START_STRING = "utm_"

  for(let key in routerQuery) {
    if(key.startsWith(UTM_START_STRING)) {
      utmObj[key] = routerQuery[key] as string
    }
  }

  return utmObj
}

export const formatDate = (value: string | number | Date): string => {
  return new Date(value)
    .toLocaleString("ru", { dateStyle: "long" })
}

const getAllFormFields = (form: HTMLFormElement) => {
  const fields = Array.from(form.elements)

  return fields.filter(field => ["SELECT", "TEXTAREA"].includes(field.tagName) ||
    (field.tagName === "INPUT" && (field as HTMLInputElement).type !== "checkbox" && field.id !== "promocode"))
}

export const setFocusByEnterForForm = (event: KeyboardEvent, formElement: Ref<HTMLFormElement | null>, callback: Function) => {
  if (event.code != "Enter" || !formElement.value) return

  event.preventDefault()

  const fields = [...getAllFormFields(formElement.value)]
  const index = fields.indexOf(event.target as HTMLElement)

  if (index === fields.length - 1) {
    callback()
  } else {
    const nextField = fields[index + 1] as HTMLElement
    nextField.focus()
    nextField.click()
  }

  return
}
