import { cx } from "cva"

const link = [
  "grid",
  "grid-flow-col",
  "auto-cols-min",
  "gap-x-2",
  "items-center",
  "group"
]

const iconWrapper = "relative"

const icon = cx([
  "p-1",
  "rounded-lg",
  "text-icon-primary-on-color",
  "cursor-pointer",
  "duration-300",
  "group-hover:bg-snow-white-400",
  "group-active:bg-snow-white-500"
])

const text = [
  "text-secondary",
  "group-hover:text-primary-on-color",
  "text-sm/[120%]",
  "duration-300"
]

const count = [
  "absolute",
  "top-0",
  "right-0",
  "flex",
  "justify-center",
  "items-center",
  "w-3",
  "h-3",
  "p-2",
  "text-primary-on-color",
  "text-[10px]",
  "font-bold",
  "leading-[130%]",
  "bg-bg-red-error",
  "rounded-full"
]

export default {
  link,
  iconWrapper,
  icon,
  text,
  count
}
