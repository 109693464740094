const title = [
  "text-[21px]/[120%]",
  "font-bold",
  "text-primary",
  "mb-6",
  "md:text-[36px]",
  "md:mb-9"
]

const dropdown = "mb-6"

export default {
  title,
  dropdown
}
