import { acceptHMRUpdate, defineStore } from "pinia"
import { Device } from "shared/api"
import { ref, computed } from "vue"
import _ from "lodash"
import { DevicesComparisonTableItem, DevicesComparisonTableItemField } from "shared/lib"

export const useUserStore = defineStore("user", {
  state: () => {
    const viewedDevices = ref<Device[]>([])
    const viewedDevicesIds = computed(() => viewedDevices.value.map(viewedDevice => viewedDevice.Id))

    const favouriteDevices = ref<Device[]>([])
    const favouriteDevicesIds = computed(() => favouriteDevices.value.map(favouriteDevice => favouriteDevice.Id))

    const comparableDevices = ref<Device[]>([])
    const comparableDevicesIds = computed(() => comparableDevices.value.map(comparableDevice => comparableDevice.Id))

    const isShowTradeInPreview = ref<Boolean>(true)

    const addViewedDevice = (device: Device) => {
      const isExist = device &&
          viewedDevices.value.findIndex(viewedDevice => viewedDevice.Id === device.Id) !== -1
      if (isExist) return

      viewedDevices.value.push(device)
    }

    const toggleFavouriteDevice = (device: Device) => {
      const isExist = favouriteDevices.value.findIndex(favouriteDevice => favouriteDevice.Id === device.Id) !== -1
      if (isExist) {
        // remove favourite device
        favouriteDevices.value = favouriteDevices.value.filter(favouriteDevice => favouriteDevice.Id !== device.Id)
      } else {
        // add favourite device
        favouriteDevices.value.push(device)
      }
    }

    const isFavouriteDevice = (device: Device) => favouriteDevicesIds.value.includes(device.Id)

    const toggleComparableDevice = (device: Device) => {
      const isExist = comparableDevices.value.findIndex(comparableDevice => comparableDevice.Id === device.Id) !== -1
      if (isExist) {
        // remove comparable device
        comparableDevices.value = comparableDevices.value.filter(comparableDevice => comparableDevice.Id !== device.Id)
      } else {
        // add comparable device
        comparableDevices.value.push(device)
      }
    }

    const isComparableDevice = (device: Device) => comparableDevicesIds.value.includes(device.Id)

    const getDevicesComparisonTable = () => {
      let table = Array<DevicesComparisonTableItem>()

      comparableDevices.value.forEach(comparableDevice => {
        table.push({
          peculiarities: getDevicesComparisonTableItemPeculiarities(comparableDevice),
          characteristics: getDevicesComparisonTableItemCharacteristics(comparableDevice),
          attributes: _.cloneDeep(comparableDevice.Attributes)
        })
      })

      table = setDashesForMissingTableFields(table)
      table.forEach(tableItem => {
        for (let key in tableItem) {
          table = sortTableFields(table, key)
        }
      })

      return table
    }

    const getDevicesComparisonTableItemPeculiarities = (device: Device) => {
      return [{
        name: "Состояние",
        value: device.ModelCondition.Name
      },
      {
        name: "Ёмкость аккумулятора",
        value: String(device.BatteryVolume)
      },
      {
        name: "Гарантия:",
        value: "Pedant.ru 1 год"
      }]
    }

    const getDevicesComparisonTableItemCharacteristics = (device: Device) => {
      return [
        {
          name: "Версия",
          value: device.Version ? device.Version : "-"
        },
        {
          name: "Цвет",
          value: device.Color.Name
        },
        {
          name: "Память",
          value: device.ModelMemory.Name
        },
        {
          name: "Комплектация",
          value: "Коробка Pedant.Market"
        },
        {
          name: "Датчик NFC",
          value: "Исправен"
        }
      ]
    }

    const setDashesForMissingTableFields = (table: Array<DevicesComparisonTableItem>) => {
      const newTable: Array<DevicesComparisonTableItem> = _.cloneDeep(table)

      const tableFields: Array<string> = []
      newTable.forEach(tableItem => {
        tableItem.attributes.forEach(attribute => {
          tableFields.push(attribute.name)
        })
      })

      const uniqueTableFields: Set<string> = new Set(tableFields)
      newTable.forEach(tableItem => {
        uniqueTableFields.forEach((item) => {
          let desired = tableItem.attributes.find(attribute => attribute.name === item)
          if (!desired) {
            tableItem.attributes.push({
              name: item,
              value: "-"
            })
          }
        })
      })

      return newTable
    }

    const getDevicesComparisonTableWithDifference = () => {
      let tableDifferences = getDevicesComparisonTable()

      tableDifferences.forEach(item => {
        for (let key in item) {
          tableDifferences = getAttributesComparison(tableDifferences, key)
        }
      })

      tableDifferences.forEach(item => {
        for (let key in item) {
          tableDifferences = sortTableFields(tableDifferences, key)
        }
      })

      return tableDifferences
    }

    const getAttributesComparison = (table: Array<DevicesComparisonTableItem>, field: string) => {
      const newTable: Array<DevicesComparisonTableItem> = _.cloneDeep(table)

      const attributesNames: Array<string> = []
      newTable.forEach(device => {
        device[field].forEach(attribute => {
          attributesNames.push(attribute.name)
        })
      })

      let comparisonObject: Record<string, string[]> = {}
      const uniqueAttributesNames: Set<string> = new Set(attributesNames)
      uniqueAttributesNames.forEach(attributesName => {
        comparisonObject[attributesName] = []
      })

      newTable.forEach(device => {
        device[field].forEach(attribute => {
          comparisonObject[attribute.name].push(attribute.value)
        })
      })

      let fieldsNamesSameValues: Array<string> = []
      for (let key in comparisonObject) {
        if (compareArrayOfStrings(comparisonObject[key])) {
          fieldsNamesSameValues.push(key)
        }
      }

      newTable.forEach(device => {
        fieldsNamesSameValues.forEach(fieldsNamesSameValue => {
          device[field].forEach((attribute, index) => {
            if (attribute.name === fieldsNamesSameValue) {
              device[field].splice(index, 1)
            }
          })
        })
      })

      return newTable
    }

    const sortTableFields = (table: Array<DevicesComparisonTableItem>, field: string) => {
      const newTable: Array<DevicesComparisonTableItem> = _.cloneDeep(table)

      newTable.map((tableItem) => {
        tableItem[field] = tableItem[field].sort((a: DevicesComparisonTableItemField, b: DevicesComparisonTableItemField) => a.name > b.name ? 1 : -1)

        return tableItem
      })

      return newTable
    }

    function compareArrayOfStrings(a: string[]) {
      return !a.some(function(b) {
        return b.toLowerCase() !== a[0].toLowerCase()
      })
    }

    return { viewedDevices, viewedDevicesIds, addViewedDevice, isShowTradeInPreview,
      favouriteDevices, favouriteDevicesIds, toggleFavouriteDevice, isFavouriteDevice,
      comparableDevices, comparableDevicesIds, toggleComparableDevice, isComparableDevice,
      getDevicesComparisonTable, getDevicesComparisonTableWithDifference }
  },
  persist: {
    storage: localStorage,
    paths: ["viewedDevices", "favouriteDevices", "comparableDevices", "isShowTradeInPreview"]
  },
  share: {
    // An array of fields that the plugin will ignore.
    omit: ["isShowTradeInPreview"],
    // Override global config for this store.
    enable: true,
    initialize: true
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(
    useUserStore,
    import.meta.hot
  ))
}
