import { ROUTE_NAMES } from "shared/constants"

export default [
  {
    name: "О нас",
    items: [
      {
        name: "Контакты",
        to: ROUTE_NAMES.CONTACTS,
        external: false,
        disabled: false
      },
      {
        name: "О компании",
        to: ROUTE_NAMES.COMPANY,
        external: false,
        disabled: false
      },
      {
        name: "Публичная оферта",
        to: "/ofertaPM",
        external: false,
        disabled: false,
        target: "_blank"
      },
      {
        name: "Реквизиты компании",
        to: ROUTE_NAMES.COMPANY_DETAILS,
        external: false,
        disabled: false
      },
      {
        name: "Политика по обработке персональных данных",
        to: "/politics",
        external: false,
        disabled: false,
        target: "_blank"
      },
      {
        name: "Правила гарантийного обслуживания",
        to: ROUTE_NAMES.GARANTIA,
        external: false,
        disabled: false
      },
      {
        name: "Адреса СЦ для гарантийного ремонта",
        to: ROUTE_NAMES.GARANTIA_SERVICE,
        external: false,
        disabled: false
      }
    ]
  },
  {
    name: "Покупателям",
    items: [
      {
        name: "Калькулятор Trade-In",
        to: ROUTE_NAMES.TRADE_IN,
        external: false,
        disabled: false
      },
      {
        name: "Каталог товаров",
        to: ROUTE_NAMES.CATALOG_CATEGORIES,
        external: false,
        disabled: false
      },
      {
        name: "Возврат",
        to: ROUTE_NAMES.RETURN,
        external: false,
        disabled: false
      },
      {
        name: "Гарантия",
        to: ROUTE_NAMES.GUARANTEE,
        external: false,
        disabled: false
      },
      {
        name: "Способы оплаты",
        to: ROUTE_NAMES.PAYMENT,
        external: false,
        disabled: false
      },
      {
        name: "Личный кабинет",
        to: "https://lk.pedant.market",
        external: true,
        disabled: false
      },
      {
        name: "Отмена подписки",
        to: "https://lk.pedant.market",
        external: true,
        disabled: false
      }
    ]
  }
]
