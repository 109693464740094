<script setup lang="ts">
import { shallowRef } from "vue"
import classes from "./classes"
import { Icon } from "../"
import {
  YANDEX_METRIKA_GOALS,
  emitYandexMetrika,
  getTelegramLink,
  getWhatsappLink
} from "shared/lib"
import { cx } from "cva"
import { useRoute } from "vue-router"
import { ROUTE_NAMES } from "shared/constants"

const route = useRoute()

const socials = shallowRef([
  {
    icon: "socials/whatsap",
    href: getWhatsappLink(),
    ariaLabel: "whatsapp"
  },
  {
    icon: "socials/telegram",
    href: getTelegramLink() + "&start=pm_qr_desktop",
    ariaLabel: "telegram"
  }
])

const reachGoal = () => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.SOCIAL_MEDIA_LINK_CLICK)

  if (route.name === ROUTE_NAMES.TRADE_IN) {
    emitYandexMetrika(YANDEX_METRIKA_GOALS.CONTACT_TI)
  }
}
</script>

<template>
  <ul :class="classes.wrapper">
    <li
      v-for="(social, index) in socials"
      :key="index"
      @click="reachGoal"
    >
      <a
        :href="social.href"
        target="_blank"
        :aria-label="social.ariaLabel"
      >
        <Icon
          :name="social.icon"
          width="36"
          height="36"
          :class-name="cx(classes.icon)"
        />
      </a>
    </li>
  </ul>
</template>
