import { cx, cva, type VariantProps } from "cva"

const baseStyle = cx([
  "relative",
  "w-full",
  "flex",
  "items-center",
  "box-border",
  "rounded-xl",
  "leading-none",
  "duration-300",
  "cursor-pointer",
  "transition"
])

const basePrimaryStyle = cx([
  baseStyle,
  "font-semibold",
  "text-primary",
  "bg-bg-yellow-yellow",
  "hover:bg-bg-yellow-yellow-hover",
  "group-hover:bg-bg-yellow-yellow-hover",
  "active:bg-bg-yellow-yellow-click",
  "disabled:text-disable",
  "disabled:bg-bg-dark-blue-disable",
  "justify-center"
])

const baseSecondaryStyle = cx([
  baseStyle,
  "font-semibold",
  "text-accent",
  "bg-bg-main-thirdly",
  "hover:bg-bg-blue-hover",
  "active:bg-bg-blue-click",
  "disabled:text-disable",
  "disabled:bg-bg-dark-blue-disable",
  "justify-center"
])

const linkStyle = cx([
  baseStyle,
  "text-accent",
  "hover:text-accent-hover",
  "active:text-accent-click",
  "disabled:text-disable-on-color"
])

const linkOnColorPrimaryStyle = cx([
  baseStyle,
  "text-primary-on-color",
  "hover:text-yellow",
  "active:text-yellow-hover",
  "disabled:text-disable-on-color"
])

const linkOnColorSecondaryStyle = cx([
  baseStyle,
  "text-secondary-on-color",
  "hover:text-primary-on-color",
  "active:text-primary-on-color",
  "disabled:text-disable-on-color"
])

export const button = cva([], {
  variants: {
    intent: {
      default: [
        "w-auto",
        "overflow-visible",
        "text-left"
      ],
      ["base-primary"]: basePrimaryStyle,
      ["base-secondary"]: baseSecondaryStyle,
      ["link"]: linkStyle,
      ["link-on-color-primary"]: linkOnColorPrimaryStyle,
      ["link-on-color-secondary"]: linkOnColorSecondaryStyle
    },
    size: {
      default: [
        "p-0"
      ],
      middle: [
        "py-4",
        "px-6"
      ],
      small: [
        "py-2",
        "px-4"
      ]
    },
    underline: {
      off: [],
      on: [
        "underline",
        "underline-offset-4"
      ]
    }
  }
})

export type ButtonProps = VariantProps<typeof button>
