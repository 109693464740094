const container = [
  "my-14",
  "lg:my-4",
  "container"
]

const list = [
  "grid",
  "grid-cols-[repeat(4,_auto)]",
  "gap-4",
  "overflow-x-scroll",
  "scroll-smooth",
  "lg:overflow-auto"
]

const title = [
  "text-center",
  "!mb-6",
  "lg:hidden"
]

const item = [
  "bg-bg-main-thirdly",
  "items-center",
  "md:items-start",
  "rounded-xl",
  "p-6",
  "pb-0",
  "flex",
  "flex-col",
  "w-[288px]",
  "h-[336px]",
  "m-h-[260px]",
  "lg:m-h-auto",
  "lg:w-[300px]",
  "lg:h-auto"
]

const itemTitle = [
  "font-bold",
  "text-primary",
  "text-[21px]/[24px]",
  "lg:text-2xl",
  "mb-1"
]

const itemText = [
  "text-[14px]/[18px]",
  "lg:text-base"
]

const itemImage = [
  "self-center"
]

export default {
  container,
  list,
  title,
  item,
  itemTitle,
  itemText,
  itemImage
}
