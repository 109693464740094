<script setup lang="ts">
import classes from "./classes"
import { cx } from "cva"
import { ref, computed, watch, onMounted, onBeforeMount } from "vue"
import { InputText, InputDate, InputPhone, Button, Icon } from "shared/ui"
import { useBreakpoints } from "@vueuse/core"
import { usePhoneVerificationStore, useSubscriptionStore } from "stores"
import { EMIT_MODAL_FUNCTION_NAMES, PHONE_VERIFICATION_CONTEXT } from "shared/constants"
import { initFullNameSuggestions, initAddressSuggestions, suggestIssuedBy, emitMixpanelEventSubscriptionOrderUpdated, emitYandexMetrika, YANDEX_METRIKA_GOALS, emitMixpanelEventSubscriptionPassportDataSaved, emitMixpanelEventSubscriptionOrderCreated, setFocusByEnterForForm } from "shared/lib"
import { PassportData } from "shared/api"

const breakpoints = useBreakpoints({ md: 768 })
const mdAndLarger  = breakpoints.greaterOrEqual("md")

import { useForm, defineRule } from "vee-validate"
import { required, alpha_spaces } from "@vee-validate/rules"

const subscriptionStore = useSubscriptionStore()
const phoneVerificationStore = usePhoneVerificationStore()

const formElement = ref<HTMLFormElement | null>(null)

const isMounting = ref<boolean>(true)

/*--------- VALIDATION FORM START ---------*/
defineRule("required", (value: string) => {
  if (!required(value)) return "Поле обязательно к заполнению"

  return true
})

defineRule("alpha_spaces", (value: string) => {
  // Без второго параметра не билдился, вставил наугад, работает
  if (!alpha_spaces(value, ["ru"])) return "Поле может содержать только буквы и пробельные символы"

  return true
})

defineRule("fioPattern", (value: string) => {
  if (!/^\S+(?:\s+\S+){2,}$/.test(value)) return "Значение должно соответствовать формату: Фамилия Имя Отчество"

  return true
})

defineRule("agePattern", (value: string) => {
  if (!value) return true

  const parts = value.split(".")
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10) - 1
  const year = parseInt(parts[2], 10)

  const birthDate = new Date(year, month, day)
  const currentDate = new Date()

  let age = currentDate.getFullYear() - birthDate.getFullYear()
  const m = currentDate.getMonth() - birthDate.getMonth()

  if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
    age--
  }

  return !(age >= 18 && age <= 68) ? "Оформление подписки доступно лицам от 18 до 68 лет" : true
})

defineRule("passportIssueDate", (value: string) => {
  if (!value) return true

  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split(".").map(Number)
    return new Date(year, month - 1, day)
  }

  const issueDate = parseDate(value)
  const currentDate = new Date()
  const minDate = new Date("1997-01-01")

  currentDate.setHours(0, 0, 0, 0)
  minDate.setHours(0, 0, 0, 0)

  const birthDate = parseDate(dateOfBirth.value)
  const fourteenYearsAfterBirth = new Date(birthDate.getFullYear() + 14, birthDate.getMonth(), birthDate.getDate())

  if (issueDate < minDate) return "Дата выдачи паспорта РФ не может быть ранее 01.01.1997"
  if (issueDate > currentDate) return "Дата выдачи паспорта не может быть в будущем"
  if (issueDate < fourteenYearsAfterBirth) return "Дата выдачи паспорта должна быть не раньше даты 14-летия"

  return true
})

defineRule("passportPattern", (value: string) => {
  if (!/.{12,}/.test(value)) return "Значение должно соответствовать формату: ## ## ######, где # - число"

  return true
})

defineRule("divisionPattern", (value: string) => {
  if (!/.{7,}/.test(value)) return "Значение должно соответствовать формату: ###-###, где # - число"

  return true
})

defineRule("phonePattern", (value: string) => {
  if (!/[^_]{18,}/.test(value)) return "Значение должно соответствовать формату: +7 (###) ###-##-##, где # - число"

  return true
})

defineRule("russianText", (value: string) => {
  if (!/^[а-яА-ЯёЁ\s.,!?:;-]+$/.test(value)) return "Значение должно быть указано на русском языке"
  return true
})

defineRule("russianAddress", (value: string) => {
  if (!/^[а-яА-ЯёЁ0-9\s.,/\-№()«»"']+$/.test(value)) return "Адрес должен быть указан на русском языке"
  return true
})

const schema = {
  fullName: "required|alpha_spaces|fioPattern",
  dateOfBirth: "required|agePattern",
  passportSeriesAndNumber: "required|passportPattern",
  dateOfPassportIssue: "required|passportIssueDate",
  divisionCode: "required|divisionPattern",
  issuedBy: "required",
  placeOfBirth: "required|russianText",
  registrationAddressRegion: "required|russianAddress",
  registrationAddressCity: "required|russianAddress",
  registrationAddressStreet: "required|russianAddress",
  registrationAddressHouse: "russianAddress",
  registrationAddressFlat: "russianAddress",
  residentialAddressRegion: "required|russianAddress",
  residentialAddressCity: "required|russianAddress",
  residentialAddressStreet: "required|russianAddress",
  residentialAddressHouse: "russianAddress",
  residentialAddressFlat: "russianAddress",
  phone: "required|phonePattern"
}

const { errors, defineField, handleSubmit, setFieldError } = useForm({
  validationSchema: schema,
  initialValues: {
    fullName: "",
    dateOfBirth: "",
    passportSeriesAndNumber: "",
    dateOfPassportIssue: "",
    divisionCode: "",
    issuedBy: "",
    placeOfBirth: "",
    registrationAddressRegion: "",
    registrationAddressCity: "",
    registrationAddressStreet: "",
    registrationAddressHouse: "",
    registrationAddressFlat: "",
    residentialAddressRegion: "",
    residentialAddressCity: "",
    residentialAddressStreet: "",
    residentialAddressHouse: "",
    residentialAddressFlat: "",
    phone: subscriptionStore.phone ?? ""
  }
})

const fieldOpts = {
  validateOnBlur: false,
  validateOnChange: true,
  validateOnInput: false,
  validateOnModelUpdate: false
}

const [fullName, fullNameAttrs] = defineField("fullName", { ...fieldOpts })
const [dateOfBirth, dateOfBirthAttrs] = defineField("dateOfBirth", { ...fieldOpts })
const [passportSeriesAndNumber, passportSeriesAndNumberAttrs] = defineField("passportSeriesAndNumber", { ...fieldOpts })
const [dateOfPassportIssue, dateOfPassportIssueAttrs] = defineField("dateOfPassportIssue", { ...fieldOpts })
const [divisionCode, divisionCodeAttrs] = defineField("divisionCode", { ...fieldOpts })
const [issuedBy, issuedByAttrs] = defineField("issuedBy", { ...fieldOpts })
const [placeOfBirth, placeOfBirthAttrs] = defineField("placeOfBirth", { ...fieldOpts })

const [registrationAddressRegion, registrationAddressRegionAttrs] = defineField("registrationAddressRegion", { ...fieldOpts })
const [registrationAddressCity, registrationAddressCityAttrs] = defineField("registrationAddressCity", { ...fieldOpts })
const [registrationAddressStreet, registrationAddressStreetAttrs] = defineField("registrationAddressStreet", { ...fieldOpts })
const [registrationAddressHouse, registrationAddressHouseAttrs] = defineField("registrationAddressHouse", { ...fieldOpts })
const [registrationAddressFlat, registrationAddressFlatAttrs] = defineField("registrationAddressFlat", { ...fieldOpts })

const isMatchesRegistrationAddress = ref<boolean>(false)
const [residentialAddressRegion, residentialAddressRegionAttrs] = defineField("residentialAddressRegion", { ...fieldOpts })
const [residentialAddressCity, residentialAddressCityAttrs] = defineField("residentialAddressCity", { ...fieldOpts })
const [residentialAddressStreet, residentialAddressStreetAttrs] = defineField("residentialAddressStreet", { ...fieldOpts })
const [residentialAddressHouse, residentialAddressHouseAttrs] = defineField("residentialAddressHouse", { ...fieldOpts })
const [residentialAddressFlat, residentialAddressFlatAttrs] = defineField("residentialAddressFlat", { ...fieldOpts })

const [phone, phoneAttrs] = defineField("phone", { ...fieldOpts })

const isFormDisabled = ref(false)
const onSubmit = handleSubmit(() => {
  isFormDisabled.value = true
  _savePassportDataToStore()
  _savePhoneToStore()

  if (!subscriptionStore.order) {
    subscriptionStore.createOrder()
      ?.then(() => {
        if (!subscriptionStore.selectedPercent) return
        emitMixpanelEventSubscriptionOrderCreated(subscriptionStore.selectedPercent.Months)
        emitYandexMetrika(YANDEX_METRIKA_GOALS.PODPISKA_USLOVIYA_KONTAKT)
        _savePassportData()
      })
  } else {
    subscriptionStore.updateOrder()
      ?.then(() => {
        emitMixpanelEventSubscriptionOrderUpdated()
        emitYandexMetrika(YANDEX_METRIKA_GOALS.PODPISKA_ZAYAVKA_NOMER)
        _savePassportData()
      })
  }
})

const _savePassportData = () => {
  subscriptionStore.savePassportData()
    ?.then(() => {
      emitMixpanelEventSubscriptionPassportDataSaved()
      // emitYandexMetrika(YANDEX_METRIKA_GOALS.PODPISKA_ZAYAVKA_PODTVERJDENIE)

      if (!subscriptionStore.order || !phone.value) return
      phoneVerificationStore.setOrderId(subscriptionStore.order.Id)
      phoneVerificationStore.setPhone(phone.value)
      phoneVerificationStore.sendVerificationCode(PHONE_VERIFICATION_CONTEXT.SUBSCRIPTION_ORDER)
      _openModalVerificatePhone()
    })
    .catch(scrollToFormStart)
    .finally(() => isFormDisabled.value = false)
}

const _savePassportDataToStore = () => {
  const prepareDate = (date: string) => {
    const splittedDate = date.split(".")
    const newDate = new Date(Date.UTC(Number(splittedDate[2]), Number(splittedDate[1]) - 1, Number(splittedDate[0])))

    return newDate.toISOString()
  }

  subscriptionStore.passportData = {
    FullName: fullName.value,
    BirthDate: prepareDate(dateOfBirth.value),
    SeriesAndNumber: passportSeriesAndNumber.value,
    DateOfIssue: prepareDate(dateOfPassportIssue.value),
    IssuedBy: issuedBy.value,
    DivisionCode: divisionCode.value,
    PlaceOfBirth: placeOfBirth.value,
    RegistrationAddressRegion: registrationAddressRegion.value,
    RegistrationAddressCity: registrationAddressCity.value,
    RegistrationAddressStreet: registrationAddressStreet.value,
    RegistrationAddressHouse: registrationAddressHouse.value,
    RegistrationAddressApartment: registrationAddressFlat.value,
    ResidentialAddressCity: residentialAddressCity.value,
    ResidentialAddressRegion: residentialAddressRegion.value,
    ResidentialAddressStreet: residentialAddressStreet.value,
    ResidentialAddressHouse: residentialAddressHouse.value,
    ResidentialAddressApartment: residentialAddressFlat.value
  } as PassportData
}

const _savePhoneToStore = () => subscriptionStore.phone = phone.value

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_VERIFICATE_PHONE
])

const _openModalVerificatePhone = () => {
  emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_VERIFICATE_PHONE)
  emitYandexMetrika(YANDEX_METRIKA_GOALS.PODPISKA_ZAYAVKA_ZAPOLNIL_ZAYAVKU)
}
/*--------- VALIDATION FORM END ---------*/

const currentDate = computed(() => new Date().toISOString().slice(0, 10))

const track = () => {
  if (Object.values(errors.value).length) {
    scrollToFormStart()
  }
}

const scrollToFormStart = () => {
  if (!formElement.value) return

  const formElementRect = formElement.value.getBoundingClientRect()
  window.scrollBy({
    top: formElementRect.top - (!mdAndLarger ? 10 : 40),
    behavior: "smooth"
  })
}

/*--------- WATCH FORM FIELDS START ---------*/
watch(divisionCode, (newDivisionCode: string) => {
  if (isMounting.value) return

  if (newDivisionCode && newDivisionCode.length === 7) {
    suggestIssuedBy(newDivisionCode, issuedBy)
  }
})

watch(registrationAddressRegion, (newValue: string) => {
  if(isMatchesRegistrationAddress.value){
    residentialAddressRegion.value = newValue
  }
})

watch(registrationAddressCity, (newValue: string) => {
  if(isMatchesRegistrationAddress.value){
    residentialAddressCity.value = newValue
  }
})

watch(registrationAddressStreet, (newValue: string) => {
  if(isMatchesRegistrationAddress.value){
    residentialAddressStreet.value = newValue
  }
})

watch(registrationAddressHouse, (newValue: string) => {
  if(isMatchesRegistrationAddress.value){
    residentialAddressHouse.value = newValue
  }
})

watch(registrationAddressFlat, (newValue: string) => {
  if(isMatchesRegistrationAddress.value){
    residentialAddressFlat.value = newValue
  }
})

watch(isMatchesRegistrationAddress, (checked: boolean) => {
  if(checked){
    residentialAddressCity.value = registrationAddressCity.value
    residentialAddressRegion.value =  registrationAddressRegion.value
    residentialAddressStreet.value = registrationAddressStreet.value
    residentialAddressHouse.value = registrationAddressHouse.value
    residentialAddressFlat.value = registrationAddressFlat.value
  }
})
/*--------- WATCH FORM FIELDS END ---------*/

onBeforeMount(() => {
  _loadPassportDataFromStore()
})

onMounted(() => {
  initFullNameSuggestions(fullName)
  initAddressSuggestions(
    registrationAddressRegion,
    registrationAddressCity,
    registrationAddressStreet,
    registrationAddressHouse,
    registrationAddressFlat,
    "registration"
  )
  initAddressSuggestions(
    residentialAddressRegion,
    residentialAddressCity,
    residentialAddressStreet,
    residentialAddressHouse,
    residentialAddressFlat,
    "residential",
    isMatchesRegistrationAddress
  )
  isMounting.value = false

  formElement.value?.addEventListener("keydown", (event) => setFocusByEnterForForm(event, formElement, () => {
    track()
    onSubmit()
  }))
})

const _loadPassportDataFromStore = () => {
  if (!subscriptionStore.passportData) return

  const { FullName, BirthDate, SeriesAndNumber, DateOfIssue, IssuedBy, DivisionCode, PlaceOfBirth,
    RegistrationAddressRegion, RegistrationAddressCity, RegistrationAddressStreet, RegistrationAddressHouse, ResidentialAddressApartment,
    ResidentialAddressRegion, ResidentialAddressCity, ResidentialAddressStreet, ResidentialAddressHouse, RegistrationAddressApartment } = subscriptionStore.passportData

  fullName.value = FullName
  dateOfBirth.value = BirthDate
  passportSeriesAndNumber.value = SeriesAndNumber
  dateOfPassportIssue.value = DateOfIssue
  divisionCode.value = DivisionCode
  issuedBy.value = IssuedBy
  placeOfBirth.value = PlaceOfBirth
  registrationAddressRegion.value = RegistrationAddressRegion,
  registrationAddressCity.value = RegistrationAddressCity,
  registrationAddressStreet.value = RegistrationAddressStreet,
  registrationAddressHouse.value = RegistrationAddressHouse,
  registrationAddressFlat.value = RegistrationAddressApartment,
  residentialAddressCity.value = ResidentialAddressCity,
  residentialAddressRegion.value = ResidentialAddressRegion,
  residentialAddressStreet.value = ResidentialAddressStreet,
  residentialAddressHouse.value = ResidentialAddressHouse,
  residentialAddressFlat.value = ResidentialAddressApartment
}

const handleFirstFieldCompletion = () => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.PODPISKA_USLOVIYA_ADDRESS)
}

defineExpose({
  scrollToFormStart
})
</script>

<template>
  <form
    ref="formElement"
    :class="classes.wrapper"
    @submit.prevent="onSubmit"
  >
    <div class="order-2 md:order-1">
      <div :class="classes.title">
        Паспортные данные
        <div :class="classes.titlePercent">
          +20% к одобрению
        </div>
      </div>
      <div :class="classes.grid">
        <div :class="classes.inputGroup">
          <div :class="[classes.input, classes.inputFullWidth]">
            <InputText
              id="fullName"
              v-model="fullName"
              v-bind="fullNameAttrs"
              type="input"
              name="fullName"
              placeholder="Фамилия Имя Отчество*"
              :maxlength="150"
              autocomplete="off"
              intent="primary"
              :error="errors.fullName || (subscriptionStore.errors['FullName'] ? subscriptionStore.errors['FullName'][0] : '')"
              @input="setFieldError('fullName', ''); subscriptionStore.errors['FullName'] = []"
            />
          </div>

          <!-- Дата рождения -->
          <div :class="classes.input">
            <InputDate
              id="dateOfBirth"
              v-model="dateOfBirth"
              v-bind="dateOfBirthAttrs"
              intent="primary"
              type="input"
              name="dateOfBirth"
              placeholder="Дата рождения"
              :error="errors.dateOfBirth || (subscriptionStore.errors['DateOfBirth'] ? subscriptionStore.errors['DateOfBirth'][0] : '')"
              :max="currentDate"
              @input="setFieldError('dateOfBirth', ''); subscriptionStore.errors['DateOfBirth'] = []"
            />
          </div>

          <!-- Серия и номер паспорта -->
          <div :class="classes.input">
            <InputText
              id="passportSeriesAndNumber"
              v-model="passportSeriesAndNumber"
              v-bind="passportSeriesAndNumberAttrs"
              intent="primary"
              type="input"
              name="passportSeriesAndNumber"
              input-mode="numeric"
              placeholder="Серия и номер паспорта*"
              :maxlength="12"
              mask="## ## ######"
              :error="errors.passportSeriesAndNumber || (subscriptionStore.errors['SeriesAndNumber'] ? subscriptionStore.errors['SeriesAndNumber'][0] : '')"
              @input="setFieldError('passportSeriesAndNumber', ''); subscriptionStore.errors['SeriesAndNumber'] = []"
            />
          </div>
          <!-- Дата выдачи паспорта -->
          <div :class="[classes.input, classes.inputMobileHalf]">
            <InputDate
              id="dateOfPassportIssue"
              v-model="dateOfPassportIssue"
              v-bind="dateOfPassportIssueAttrs"
              intent="primary"
              type="input"
              name="dateOfPassportIssue"
              placeholder="Дата выдачи*"
              :error="errors.dateOfPassportIssue || (subscriptionStore.errors['DateOfPassportIssue'] ? subscriptionStore.errors['DateOfPassportIssue'][0] : '')"
              :max="currentDate"
              @input="setFieldError('dateOfPassportIssue', ''); subscriptionStore.errors['DateOfPassportIssue'] = []"
            />
          </div>

          <!-- Код подразделения -->
          <div :class="[classes.input, classes.inputMobileHalf]">
            <InputText
              id="divisionCode"
              v-model="divisionCode"
              v-bind="divisionCodeAttrs"
              intent="primary"
              type="input"
              name="divisionCode"
              input-mode="numeric"
              placeholder="Код подразделения*"
              :maxlength="7"
              mask="###-###"
              :error="errors.divisionCode || (subscriptionStore.errors['DivisionCode'] ? subscriptionStore.errors['DivisionCode'][0] : '')"
              @input="setFieldError('divisionCode', ''); subscriptionStore.errors['DivisionCode'] = []"
            />
          </div>

          <!-- Кем выдан паспорт -->
          <div :class="[classes.input, classes.inputMobileHalf]">
            <InputText
              id="issuedBy"
              v-model="issuedBy"
              v-bind="issuedByAttrs"
              intent="primary"
              :class-name="cx([classes.input, classes.inputTwoCol])"
              type="input"
              name="issuedBy"
              placeholder="Кем выдан*"
              :maxlength="100"
              :error="errors.issuedBy || (subscriptionStore.errors['IssuedBy'] ? subscriptionStore.errors['IssuedBy'][0] : '')"
              @input="setFieldError('issuedBy', ''); subscriptionStore.errors['IssuedBy'] = []"
            />
          </div>

          <!-- Место рождения -->
          <div :class="[classes.input, classes.inputFullWidth]">
            <InputText
              id="placeOfBirth"
              v-model="placeOfBirth"
              v-bind="placeOfBirthAttrs"
              intent="primary"
              type="input"
              name="placeOfBirth"
              placeholder="Место рождения*"
              :maxlength="100"
              :error="errors.placeOfBirth || (subscriptionStore.errors['PlaceOfBirth'] ? subscriptionStore.errors['PlaceOfBirth'][0] : '')"
              @input="setFieldError('placeOfBirth', ''); subscriptionStore.errors['PlaceOfBirth'] = []"
            />
          </div>
        </div>

        <div>
          <div :class="classes.title">
            Адрес регистрации
            <div :class="classes.titlePercent">
              +10% к одобрению
            </div>
          </div>
          <div :class="classes.inputGroup">
            <!-- Адрес регистрации: Регион -->
            <div :class="classes.input">
              <InputText
                id="registrationAddressRegion"
                v-model="registrationAddressRegion"
                v-bind="registrationAddressRegionAttrs"
                intent="primary"
                type="input"
                name="registrationAddressRegion"
                placeholder="Субъект РФ (область, край)*"
                :maxlength="100"
                :error="errors.registrationAddressRegion || (subscriptionStore.errors['RegistrationAddressRegion'] ? subscriptionStore.errors['RegistrationAddressRegion'][0] : '')"
                @focusout="handleFirstFieldCompletion"
                @input="setFieldError('registrationAddressRegion', ''); subscriptionStore.errors['RegistrationAddressRegion'] = []"
              />
            </div>

            <!-- Адрес регистрации: Город или населённый пункт -->
            <div :class="classes.input">
              <InputText
                id="registrationAddressCity"
                v-model="registrationAddressCity"
                v-bind="registrationAddressCityAttrs"
                intent="primary"
                type="input"
                name="registrationAddressCity"
                placeholder="Город или населённый пункт*"
                :maxlength="100"
                :error="errors.registrationAddressCity || (subscriptionStore.errors['RegistrationAddressCity'] ? subscriptionStore.errors['RegistrationAddressCity'][0] : '')"
                @input="setFieldError('registrationAddressCity', ''); subscriptionStore.errors['RegistrationAddressCity'] = []"
              />
            </div>

            <!-- Адрес регистрации: Улица -->
            <div :class="classes.input">
              <InputText
                id="registrationAddressStreet"
                v-model="registrationAddressStreet"
                v-bind="registrationAddressStreetAttrs"
                intent="primary"
                type="input"
                name="registrationAddressStreet"
                placeholder="Улица*"
                :maxlength="100"
                :error="errors.registrationAddressStreet || (subscriptionStore.errors['RegistrationAddressStreet'] ? subscriptionStore.errors['RegistrationAddressStreet'][0] : '')"
                @input="setFieldError('registrationAddressStreet', ''); subscriptionStore.errors['RegistrationAddressStreet'] = []"
              />
            </div>

            <!-- Адрес регистрации: Дом -->
            <InputText
              id="registrationAddressHouse"
              v-model="registrationAddressHouse"
              v-bind="registrationAddressHouseAttrs"
              :class-name="cx(classes.inputMobileHalf)"
              intent="primary"
              type="input"
              name="registrationAddressHouse"
              placeholder="Дом"
              :maxlength="20"
              :error="errors.registrationAddressHouse || (subscriptionStore.errors['registrationAddressHouse'] ? subscriptionStore.errors['registrationAddressHouse'][0] : '')"
              @input="setFieldError('registrationAddressHouse', ''); subscriptionStore.errors['registrationAddressHouse'] = []"
            />
            <!-- Адрес регистрации: Квартира -->
            <InputText
              id="registrationAddressFlat"
              v-model="registrationAddressFlat"
              v-bind="registrationAddressFlatAttrs"
              :class-name="cx(classes.inputMobileHalf)"
              intent="primary"
              type="input"
              name="registrationAddressFlat"
              placeholder="Квартира"
              :maxlength="10"
              :error="errors.registrationAddressFlat || (subscriptionStore.errors['registrationAddressFlat'] ? subscriptionStore.errors['registrationAddressFlat'][0] : '')"
              @input="setFieldError('registrationAddressFlat', ''); subscriptionStore.errors['registrationAddressFlat'] = []"
            />
          </div>
        </div>

        <div>
          <div :class="classes.title">
            Адрес проживания
            <div :class="classes.titlePercent">
              +10% к одобрению
            </div>
          </div>
          <div :class="classes.checkbox">
            <input
              id="isMatchesRegistrationAddress"
              v-model="isMatchesRegistrationAddress"
              :class="classes.checkboxInput"
              type="checkbox"
              :checked="isMatchesRegistrationAddress"
            >
            <label
              for="isMatchesRegistrationAddress"
              :class="classes.checkboxLabel"
            >
              Совпадает с адресом регистрации
            </label>
          </div>
          <div :class="classes.inputGroup">
            <!-- Адрес регистрации: Регион -->
            <div :class="classes.input">
              <InputText
                id="residentialAddressRegion"
                v-model="residentialAddressRegion"
                v-bind="residentialAddressRegionAttrs"
                intent="primary"
                type="input"
                name="residentialAddressRegion"
                placeholder="Субъект РФ (область, край)*"
                autocomplete="off"
                :maxlength="100"
                :error="errors.residentialAddressRegion || (subscriptionStore.errors['ResidentialAddressRegion'] ? subscriptionStore.errors['ResidentialAddressRegion'][0] : '')"
                :readonly="isMatchesRegistrationAddress"
                @input="setFieldError('residentialAddressRegion', ''); subscriptionStore.errors['ResidentialAddressRegion'] = []"
              />
            </div>

            <!-- Адрес Проживания: Город или населённый пункт -->
            <div :class="classes.input">
              <InputText
                id="residentialAddressCity"
                v-model="residentialAddressCity"
                v-bind="residentialAddressCityAttrs"
                intent="primary"
                type="input"
                name="residentialAddressCity"
                placeholder="Город или населённый пункт*"
                :maxlength="100"
                :error="errors.residentialAddressCity || (subscriptionStore.errors['ResidentialAddressCity'] ? subscriptionStore.errors['ResidentialAddressCity'][0] : '')"
                :readonly="isMatchesRegistrationAddress"
                @input="setFieldError('residentialAddressCity', ''); subscriptionStore.errors['ResidentialAddressCity'] = []"
              />
            </div>

            <!-- Адрес Проживания: Улица -->
            <div :class="classes.input">
              <InputText
                id="residentialAddressStreet"
                v-model="residentialAddressStreet"
                v-bind="residentialAddressStreetAttrs"
                intent="primary"
                type="input"
                name="residentialAddressStreet"
                placeholder="Улица*"
                :maxlength="100"
                :error="errors.residentialAddressStreet || (subscriptionStore.errors['ResidentialAddressStreet'] ? subscriptionStore.errors['ResidentialAddressStreet'][0] : '')"
                :readonly="isMatchesRegistrationAddress"
                @input="setFieldError('residentialAddressStreet', ''); subscriptionStore.errors['ResidentialAddressStreet'] = []"
              />
            </div>

            <!-- Адрес Проживания: Дом -->
            <InputText
              id="residentialAddressHouse"
              v-model="residentialAddressHouse"
              v-bind="residentialAddressHouseAttrs"
              :class-name="cx(classes.inputMobileHalf)"
              intent="primary"
              type="input"
              name="residentialAddressHouse"
              placeholder="Дом"
              :maxlength="20"
              :readonly="isMatchesRegistrationAddress"
              :error="errors.residentialAddressHouse || (subscriptionStore.errors['residentialAddressHouse'] ? subscriptionStore.errors['residentialAddressHouse'][0] : '')"
              @input="setFieldError('residentialAddressHouse', ''); subscriptionStore.errors['residentialAddressHouse'] = []"
            />

            <!-- Адрес Проживания: Квартира -->
            <InputText
              id="residentialAddressFlat"
              v-model="residentialAddressFlat"
              v-bind="residentialAddressFlatAttrs"
              :class-name="cx(classes.inputMobileHalf)"
              intent="primary"
              type="input"
              name="residentialAddressFlat"
              placeholder="Квартира"
              :maxlength="10"
              :readonly="isMatchesRegistrationAddress"
              :error="errors.residentialAddressFlat || (subscriptionStore.errors['residentialAddressFlat'] ? subscriptionStore.errors['residentialAddressFlat'][0] : '')"
              @input="setFieldError('residentialAddressFlat', ''); subscriptionStore.errors['residentialAddressFlat'] = []"
            />
          </div>
        </div>

        <div>
          <div :class="classes.actionTitle">
            Номер телефона
          </div>
          <div :class="classes.actionGrid">
            <!-- Телефон -->
            <div :class="classes.input">
              <InputPhone
                id="phone"
                v-model="phone"
                v-bind="phoneAttrs"
                placeholder="Номер телефона*"
                intent="primary"
                type="input"
                class-name="w-full"
                :error="errors.phone"
                @input="setFieldError('phone', '')"
              />
            </div>
            <Button
              intent="base-primary"
              size="middle"
              :class="classes.actionBtn"
              :disabled="isFormDisabled"
              @click="track()"
            >
              Продолжить
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="order-1 md:order-2">
      <div :class="classes.text">
        <Icon
          name="common/info"
          width="19"
          height="18"
        />
        <div>
          <strong class="block mb-1">
            Проверьте введённые данные.
          </strong>
          <div>
            Мы не сможем выдать смартфон, если данные не будут совпадать с паспортом.
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<style>
.autocomplete {
  z-index: 15;
}

.text-input-autocomplete.autocomplete>div:hover:not(.group),
.text-input-autocomplete.autocomplete>div.selected {
  background: #f3f3f3;
}

.text-input-autocomplete.autocomplete>div {
  padding: 10px 4px;
}
</style>
