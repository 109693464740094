<script setup lang="ts">
import classes from "./classes"
import { ref, watch, onMounted } from "vue"
import { InputText, Button, InputPromoCode, InputPhone } from "shared/ui"
import { YANDEX_METRIKA_GOALS, emitMixpanelEventCartProceededToCheckout, emitYandexMetrika, formatCurrency, initFullNameSuggestions, setFocusByEnterForForm } from "shared/lib"
import { useCartStore, usePhoneVerificationStore } from "stores"
import { storeToRefs } from "pinia"
import { EMIT_MODAL_FUNCTION_NAMES, PHONE_VERIFICATION_CONTEXT } from "shared/constants"

import { useForm, defineRule } from "vee-validate"
import { required, alpha_spaces } from "@vee-validate/rules"

const cartStore = useCartStore()
const phoneVerificationStore = usePhoneVerificationStore()

const isFormDisabled = ref(false)
const email = ref("")

const form = ref<HTMLFormElement | null>(null)

const { totalSum, totalSumAfterDiscount, paymentMethod, promoCodeDiscount, paymentMethodDiscount, order } = storeToRefs(cartStore)

const createOrder = () => {
  isFormDisabled.value = true

  if (cartStore.device) {
    emitMixpanelEventCartProceededToCheckout(cartStore.device, cartStore.isPromocodeApplied)
    emitYandexMetrika(YANDEX_METRIKA_GOALS.PEREJTI_K_OPLATE)
  }

  const orderData = {
    CustomerName: fullName.value,
    CustomerPhone: phone.value,
    Email: email.value
  }

  cartStore.saveUserData(orderData)
  cartStore.createOrder().then(() => {
    isFormDisabled.value = false
  })
}

watch(order, (newOrder) => {
  if (newOrder?.Id) {
    phoneVerificationStore.setOrderId(newOrder.Id)
    phoneVerificationStore.setPhone(phone.value)
    phoneVerificationStore.sendVerificationCode(PHONE_VERIFICATION_CONTEXT.ORDER)
    openModalVerificatePhone()
  }
})

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_VERIFICATE_PHONE
])

const openModalVerificatePhone = () => emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_VERIFICATE_PHONE)

/*--------- VALIDATION FORM START ---------*/
defineRule("required", (value: string) => {
  if (!required(value)) return "Поле обязательно к заполнению"

  return true
})

defineRule("alpha_spaces", (value: string) => {
  // Без второго параметра не билдился, вставил наугад, работает
  if (!alpha_spaces(value, ["ru"])) return "Поле может содержать только буквы и пробельные символы"

  return true
})

defineRule("fioPattern", (value: string) => {
  if (!/^\S+(?:\s+\S+){2,}$/.test(value)) return "Значение должно соответствовать формату: Фамилия Имя Отчество"

  return true
})

defineRule("phonePattern", (value: string) => {
  if (!/[^_]{18,}/.test(value)) return "Значение должно соответствовать формату: +7 (###) ###-##-##, где # - число"

  return true
})

const schema = {
  fullName: "required|alpha_spaces|fioPattern",
  phone: "required|phonePattern"
}

const { errors, defineField, handleSubmit, setFieldError  } = useForm({
  validationSchema: schema
})

const fieldOpts = {
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
}

const [fullName, fullNameAttrs] = defineField("fullName", { ...fieldOpts })
const [phone, phoneAttrs] = defineField("phone", { ...fieldOpts })

const onSubmit = handleSubmit(createOrder)
/*--------- VALIDATION FORM END ---------*/

onMounted(() => {
  initFullNameSuggestions(fullName)

  form.value?.addEventListener("keydown", (event) => setFocusByEnterForForm(event, form, onSubmit))
})
</script>

<template>
  <form
    id="cart-form"
    ref="form"
    @submit.prevent="onSubmit"
  >
    <div :class="classes.userSection">
      <div :class="classes.userSectionTitle">
        Укажите Ваши данные
      </div>
      <div :class="classes.userSectionNameGrid">
        <InputText
          id="fullName"
          v-model="fullName"
          v-bind="fullNameAttrs"
          type="input"
          name="fullName"
          placeholder="Фамилия Имя Отчество"
          :maxlength="150"
          autocomplete="off"
          intent="primary"
          class-name="w-full"
          :error="errors.fullName"
          @input="setFieldError('fullName', '')"
        />
      </div>
      <div :class="classes.userSectionContactGrid">
        <div :class="classes.userSectionContactInput">
          <InputPhone
            id="phone"
            v-model="phone"
            v-bind="phoneAttrs"
            placeholder="Номер телефона"
            intent="primary"
            type="input"
            class-name="w-full"
            :error="errors.phone"
            @input="setFieldError('phone', '')"
          />
          <div :class="classes.userSectionContactNotice">
            — позвоним для подтверждения заказа
          </div>
        </div>
        <div :class="classes.userSectionContactInput">
          <InputText
            id="email"
            v-model="email"
            placeholder="E-mail"
            intent="primary"
            type="input"
            class-name="w-full"
            input-type="email"
            input-mode="email"
          />
          <div :class="classes.userSectionContactNotice">
            — отправим документы вам на почту
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="totalSum"
      :class="classes.totalSection"
    >
      <div :class="classes.totalSectionTitle">
        Ваш заказ
      </div>
      <div :class="classes.totalSectionContent">
        <div :class="classes.totalSectionList">
          <div :class="classes.totalSectionItem">
            <div>Сумма заказа</div>
            <div>{{ formatCurrency(totalSum) }} ₽</div>
          </div>
          <div
            v-if="promoCodeDiscount"
            :class="classes.totalSectionItem"
          >
            <div>Скидка по промокоду</div>
            <div>- {{ formatCurrency(promoCodeDiscount) }} ₽</div>
          </div>
          <div
            v-if="paymentMethodDiscount"
            :class="classes.totalSectionItem"
          >
            <div>Скидка за онлайн-оплату</div>
            <div>- {{ formatCurrency(paymentMethodDiscount) }} ₽</div>
          </div>
        </div>
        <InputPromoCode />
        <div :class="classes.totalSectionResult">
          <div :class="classes.totalSectionItem">
            <div>К оплате</div>
            <div :class="classes.totalSectionItemValue">
              {{ formatCurrency(totalSumAfterDiscount) }}
            </div>
          </div>
        </div>
        <Button
          intent="base-primary"
          size="middle"
          :class="classes.totalSectionButton"
          :disabled="isFormDisabled"
        >
          {{ paymentMethod?.buttonText }}
        </Button>
      </div>
    </div>
  </form>
</template>

<style>
.autocomplete {
  z-index: 15;
}

.text-input-autocomplete.autocomplete>div:hover:not(.group),
.text-input-autocomplete.autocomplete>div.selected {
  background: #f3f3f3;
}

.text-input-autocomplete.autocomplete>div {
  padding: 10px 4px;
}
</style>
