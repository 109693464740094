import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"
import api, { type Device } from "shared/api"

export const useSearchStore = defineStore("search", () => {
  const list = ref<Device[]>([])

  const isLoading = ref(true)

  async function search(text: string) {
    isLoading.value = true

    const { data } = await api.yandex.search(text, 1)
    if (!data.documents) {
      list.value = []
      isLoading.value = false
    }

    // get devices ids
    const ids = data.documents.map((document: { url: string }) => document.url.slice(document.url.lastIndexOf("/") + 1))

    api.devices.getList({ Ids: ids })
      .then(({ data }) => {
        list.value = data.data
      })
      .catch(() => {
        list.value = []
      })
      .finally(() => isLoading.value = false)
  }

  return {
    isLoading,
    list,
    search
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot))
}
