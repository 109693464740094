import { RouteLocationNormalized, createRouter, createWebHistory, NavigationGuardNext } from "vue-router"
import { routes, redirects } from "pages"

import {
  useCitiesStore,
  useReviewsStore,
  useSeoContentStore,
  useServiceCentersStore,
  useDevicesStore,
  useModelGroupsStore,
  useModelMemoriesStore,
  useModelColorsStore,
  useCatalogStore,
  useUtmStore,
  useSubscriptionStore,
  useSearchStore,
  useSalesHitsStore,
  useCartStore
} from "stores"
import { DEVICES_SORT_BY, ROUTE_NAMES } from "shared/constants"
import {
  redirectToFileIfPossible,
  parseUtmTags,
  redirectToPersonalCabinetIfPossible,
  redirectIfShortLinkFound,
  redirectIfSigningLinkFound,
  redirectToHomeIfPossible,
  redirectToOutOfStockIfPossible,
  redirectTo404IfCatalogDeviceNotFound,
  redirectToPaymentIfPossible, emitYandexMetrika, YANDEX_METRIKA_GOALS,
  getCatalogDeviceColorsAndMemoriesIfPossible
} from "shared/lib"

import { initMixpanel } from "shared/lib"
import mixpanel from "mixpanel-browser"

export const router = createRouter({
  history: createWebHistory(),
  routes: [...routes, ...redirects],
  scrollBehavior(to, from) {
    // При изменении способа оплаты, изменяется key в адресной строке и осуществляется прокрутка к радио-элементу
    const isChangedKeyForCartPage = to.name === ROUTE_NAMES.CART && from.name === ROUTE_NAMES.CART && to.query?.key
    if (isChangedKeyForCartPage) {
      return {
        el: "input[name='payment-method-radio-input']:checked",
        top: 200
      }
    }

    // always scroll to top
    return { top: 0 }
  }
})

router.beforeEach(async (currentRoute: RouteLocationNormalized, prevRoute: RouteLocationNormalized, next: NavigationGuardNext) => {
  initMixpanel()

  if (await redirectToPaymentIfPossible(currentRoute, next)) return
  if (await redirectIfSigningLinkFound(currentRoute)) return
  if (await redirectIfShortLinkFound(currentRoute)) return
  if (redirectToPersonalCabinetIfPossible(currentRoute)) return
  if (redirectToFileIfPossible(currentRoute)) return
  if (redirectToHomeIfPossible(currentRoute, next)) return
  if (await redirectToOutOfStockIfPossible(currentRoute, next)) return
  if (await redirectTo404IfCatalogDeviceNotFound(currentRoute, next)) return

  const citiesStore = useCitiesStore()
  const reviewsStore = useReviewsStore()
  const seoContentStore = useSeoContentStore()
  const serviceCentersStore = useServiceCentersStore()
  const devicesStore = useDevicesStore()
  const modelGroupsStore = useModelGroupsStore()
  const modelMemoriesStore = useModelMemoriesStore()
  const modelColorsStore = useModelColorsStore()
  const catalogStore = useCatalogStore()
  const subscriptionStore = useSubscriptionStore()
  const searchStore = useSearchStore()
  const salesHitsStore = useSalesHitsStore()
  const cartStore = useCartStore()

  // загружаем список городов
  // получаем по ip-адресу город через сервис dadata
  // находим текущий город в списке городов по имени
  if (!citiesStore.list.length) await citiesStore.getList()
    .then(async () => {
      if (!citiesStore.hasConfirm) await citiesStore.getCurrent()
    })

  // загружаем сервисные центры города
  if (!serviceCentersStore.list.length) serviceCentersStore.getList()

  // загружаем SEO-контент
  const isNotCatalogPage = currentRoute.name !== ROUTE_NAMES.CATALOG_MODEL && currentRoute.name !== ROUTE_NAMES.CATALOG_MODEL_GROUP
  if (isNotCatalogPage) seoContentStore.getList(currentRoute.path)

  // загружаем отзывы
  if (!reviewsStore.list.length) reviewsStore.getList()

  // загружаем устройства (хиты продаж)
  if (!salesHitsStore.list.length) salesHitsStore.getList()

  // загружаем устройства
  if (!devicesStore.list.length) devicesStore.getList({
    SortBy: DEVICES_SORT_BY.IS_POPULAR,
    SortDirection: "desc",
    IsNew: 0,
    ParentId: "21876e69-9d87-11e5-9312-f452145fc090"
  })

  // загружаем категории моделей
  if (!modelGroupsStore.list.length) await modelGroupsStore.getList()

  // загружаем варианты памяти моделей
  if (!modelMemoriesStore.list.length) await modelMemoriesStore.getList()

  // загружаем варианты цветов моделей
  if (!modelColorsStore.list.length) await modelColorsStore.getList()

  const isSearchPage = currentRoute.name === ROUTE_NAMES.SEARCH
  if (isSearchPage && currentRoute.params.searchQuery && typeof currentRoute.params.searchQuery === "string") {
    searchStore.search(currentRoute.params.searchQuery)
  }

  const isSubscriptionPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION
  const isSubscriptionFormPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION_FORM
  const isSubscriptionFormFilledPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION_FORM_FILLED

  if ((isSubscriptionPage || isSubscriptionFormPage || isSubscriptionFormFilledPage) && !subscriptionStore.percents) {
    await subscriptionStore.getPercents()
      .then(() => subscriptionStore.setDefaultPercent())
  }

  if (isSubscriptionPage || isSubscriptionFormPage || isSubscriptionFormFilledPage) {
    await subscriptionStore.getCheaperDevices()
  }

  const isCatalogDevicePage = currentRoute.name === ROUTE_NAMES.CATALOG_DEVICE
  if (isCatalogDevicePage) {
    if (!subscriptionStore.percents) {
      await subscriptionStore.getPercents()
        .then(() => subscriptionStore.setDefaultPercent())
    }

    getCatalogDeviceColorsAndMemoriesIfPossible()
  }

  const isCartThanksPage = currentRoute.name === ROUTE_NAMES.THANKS
  if (isCartThanksPage && currentRoute.params.id && typeof currentRoute.params.id === "string") {
    await cartStore.getOrder(currentRoute.params.id)
  }

  const isSubscriptionThanksPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION_THANKS
  if (isSubscriptionThanksPage && currentRoute.params.id && typeof currentRoute.params.id === "string") {
    await subscriptionStore.getOrder(currentRoute.params.id)
  }

  const isCatalogPage = currentRoute.name === ROUTE_NAMES.CATALOG_MODEL || currentRoute.name === ROUTE_NAMES.CATALOG_MODEL_GROUP
  if (isCatalogPage && !catalogStore.checkFilter(currentRoute)) {
    emitYandexMetrika(YANDEX_METRIKA_GOALS.PAGE_NOT_FOUND, {
      from: currentRoute.fullPath
    })
    mixpanel.track("page_not_found", {
      from: currentRoute.fullPath
    })
    next({ name: ROUTE_NAMES.NOT_FOUND })
  } else {
    if ((currentRoute.params.modelGroupSlug || prevRoute.params.modelGroupSlug) && currentRoute.params.modelGroupSlug !== prevRoute.params.modelGroupSlug) {
      const foundModelGroup = modelGroupsStore.list.find(currentModelGroup => currentModelGroup.url === `/${currentRoute.params.modelGroupSlug}`)
      if (foundModelGroup) {
        catalogStore.filter.ParentId = foundModelGroup.id
        catalogStore.filter.Models = null
        catalogStore.resetFilters()
      }
    }
    next()
  }
})

router.afterEach((to: RouteLocationNormalized) => {
  const utmStore = useUtmStore()
  utmStore.init(parseUtmTags(to.query))
})

// https://github.com/vitejs/vite/issues/11804
router.onError((error, to) => {
  if (error.message.includes("Failed to fetch dynamically imported module")) {
    window.location.href = to.fullPath
  }
})
