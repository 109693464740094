<script setup lang="ts">
import classes from "./classes"
import { ref, PropType, computed } from "vue"
import { Device } from "shared/api"
import { Button, Battery, QuestionTooltip, Icon } from "shared/ui"
import { DeviceProperty, YANDEX_METRIKA_GOALS, checkIsIPhone, checkIsNew, emitMixpanelEventCardColorSelectionClick, emitYandexMetrika, getSim } from "shared/lib"
import { useCatalogDeviceStore, useUserStore } from "stores"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import { DEVICE_PROPERTY_KEYS } from "shared/constants"

const catalogDeviceStore = useCatalogDeviceStore()
const userStore = useUserStore()
const { isLoading } = storeToRefs(catalogDeviceStore)

const router = useRouter()

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  },
  hasFilters: {
    type: Boolean,
    default: false
  },
  isSubscriptionPage: {
    type: Boolean,
    default: false,
    required: false
  }
})

const { memories, colors, selectedColor, selectedMemory } = storeToRefs(catalogDeviceStore)

const isNewDevice = computed(() => checkIsNew(props.device))
const isIPhoneDevice = computed(() => checkIsIPhone(props.device))
const deviceSim = computed(() => getSim(props.device))

const deviceModelConditionQuestionTooltipText = computed(() => {
  if (props.device.ModelCondition.Name === "Отличное")
    return "Могут присутствовать совсем незначительные царапины по корпусу и экрану,  которые не вредят внешнему виду устройства и его эксплуатации."
  else if (props.device.ModelCondition.Name === "Хорошее")
    return "Допускаются следы износа, царапины по экрану, корпусу, но без видимых и глубоких царапин и сколов корпуса."
  else return ""
})

const peculiarities = computed(() => <DeviceProperty[]>[
  {
    key: DEVICE_PROPERTY_KEYS.STATE,
    name: "Состояние",
    value: `${props.device.ModelCondition.Name} ${!isNewDevice.value ? "б/у" : ""}`
  },
  {
    key: DEVICE_PROPERTY_KEYS.BATTERY_CAPACITY,
    name: "Ёмкость аккумулятора",
    isHidden: !(!isNewDevice.value && isIPhoneDevice.value)
  }
])

const specifications = computed(() => <DeviceProperty[]>[
  {
    key: DEVICE_PROPERTY_KEYS.APPLE_ID,
    name: "Apple ID (iCloud)",
    value: isNewDevice.value ? "Новый" : "Отвязан",
    isHidden: !isIPhoneDevice.value
  },
  {
    key: DEVICE_PROPERTY_KEYS.GARANTEE,
    name: "Гарантия",
    value: `${isNewDevice.value ? "Официальная" : "Pedant.ru"} 1 год`
  },
  {
    key: DEVICE_PROPERTY_KEYS.IMEI,
    name: "IMEI",
    value: `************${props.device.ImeiLastFourSymbols}`
  },
  {
    key: DEVICE_PROPERTY_KEYS.COLOR,
    name: "Цвет",
    value: props.device.Color.Name,
    isHidden: !props.isSubscriptionPage
  },
  {
    key: DEVICE_PROPERTY_KEYS.MEMORY,
    name: "Память",
    value: props.device.ModelMemory.Name,
    isHidden: !props.isSubscriptionPage
  },
  {
    key: DEVICE_PROPERTY_KEYS.VERSION,
    name: "Версия",
    value: props.device.Version,
    isHidden: !props.device.Version
  },
  {
    key: DEVICE_PROPERTY_KEYS.SIM,
    name: "SIM карта",
    value: deviceSim.value,
    isHidden: !deviceSim.value
  },
  {
    key: DEVICE_PROPERTY_KEYS.ACCESSORIES,
    name: "Комплектация",
    value: isNewDevice.value ? "Оригинальная коробка" : "Коробка Pedant.Market"
  },
  {
    key: DEVICE_PROPERTY_KEYS.NFC,
    name: "Датчик NFC",
    value: "Исправен",
    isHidden: !(!isNewDevice.value && isIPhoneDevice.value)
  }
])

const isShowAllText = ref(false)
const showAllText = () => isShowAllText.value = !isShowAllText.value

const emit = defineEmits(["openModalShowSpecifications"])
const showAllSpecifications = () => emit("openModalShowSpecifications")

const getModel = (name: string, id: string) => {
  if (!selectedColor.value || !selectedMemory.value) return

  if(name === "Colors"){
    emitYandexMetrika(YANDEX_METRIKA_GOALS.CARD_COLOR_SELECTION_CLICK)
    emitMixpanelEventCardColorSelectionClick(selectedColor.value.name, props.device)
  }

  if (name === "Memories") {
    emitYandexMetrika(YANDEX_METRIKA_GOALS.CARD_MEMORY_SELECTION_CLICK)
    emitMixpanelEventCardColorSelectionClick(selectedMemory.value.name, props.device)
  }

  // запрос по памяти и цвету
  catalogDeviceStore.getItems({
    ParentId: props.device.ParentId,
    Models: [props.device.ModelId],
    Memories: [selectedMemory.value.id],
    Colors: [selectedColor.value.id],
    SortBy: "PurchaseDate"
  })?.then(devices => {
    if (devices.length > 0) {
      saveDevice(devices[0])
    } else {
      // запрос по памяти либо по цвету
      catalogDeviceStore.getItems({
        ParentId: props.device.ParentId,
        Models: [props.device.ModelId],
        [name]: [id],
        SortBy: "PurchaseDate"
      })?.then(devices => {
        saveDevice(devices[0])
      })
    }
  })
}

const saveDevice = (device: Device) => {
  catalogDeviceStore.setItem(device)
  userStore.addViewedDevice(device)
  selectedColor.value = {
    id: device.Color.Id,
    name: device.Color.Name,
    hex: device.Color.ColorCode,
    slug: device.Color.Slug
  }
  selectedMemory.value = {
    id: device.ModelMemory.Id,
    name: device.ModelMemory.Name,
    slug: device.ModelMemory.Slug
  }
  router.replace({path: `/${device.ParentSlug}/${device.ModelSlug}/${device.Id}`})
}
</script>

<template>
  <div>
    <div :class="classes.peculiarities">
      <div>
        <template
          v-for="devicePropertyItem in peculiarities"
          :key="devicePropertyItem.key"
        >
          <div
            v-if="!devicePropertyItem.isHidden"
            :class="classes.item"
          >
            <div :class="[classes.itemName, 'text-primary font-bold']">
              {{ devicePropertyItem.name }}:
            </div>
            <div :class="classes.itemValue">
              <template v-if="devicePropertyItem.value">
                {{ devicePropertyItem.value }}
              </template>
              <QuestionTooltip v-if="devicePropertyItem.key === DEVICE_PROPERTY_KEYS.STATE && deviceModelConditionQuestionTooltipText">
                {{ deviceModelConditionQuestionTooltipText }}
              </QuestionTooltip>
              <Battery
                v-else-if="devicePropertyItem.key === DEVICE_PROPERTY_KEYS.BATTERY_CAPACITY"
                :percent="device.BatteryVolume"
                :has-tooltip="!device.Badges.NewBattery"
              />
            </div>
          </div>
        </template>
      </div>
      <div
        v-if="hasFilters"
        :class="classes.deviceFilters"
      >
        <div
          v-if="selectedMemory && memories.length > 1"
          :class="classes.deviceFiltersMemory"
        >
          <div :class="classes.deviceFiltersName">
            <span>Память:</span> {{ selectedMemory.name }}
          </div>
          <div
            :class="classes.deviceFiltersMemoryList"
            :style="{'grid-template-columns': `repeat(${memories.length}, min-content)`}"
          >
            <Button
              v-for="(item, index) in memories"
              :key="index"
              :class="[classes.deviceFiltersMemoryItem, selectedMemory.id === item.id ? classes.deviceFiltersMemoryItemSelected : '']"
              :disabled="isLoading"
              @click="selectedMemory = item; getModel('Memories', item.id)"
            >
              {{ item.name }}
            </Button>
          </div>
        </div>
        <div
          v-if="selectedColor && colors.length > 1"
          :class="classes.deviceFiltersColor"
        >
          <div :class="classes.deviceFiltersName">
            <span>Цвет:</span> {{ selectedColor.name }}
          </div>
          <div
            :class="classes.deviceFiltersColorList"
            :style="{ 'grid-template-columns': `repeat(${colors.length}, min-content)`}"
          >
            <Button
              v-for="(item, index) in colors"
              :key="index"
              :class="[classes.deviceFiltersColorItem, selectedColor.id === item.id ? classes.deviceFiltersColorItemSelected : '']"
              :style="{ 'background': item.hex}"
              :disabled="isLoading"
              @click="selectedColor = item; getModel('Colors', item.id)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="device.MarketDevice?.Defects"
      :class="classes.description"
    >
      <div :class="classes.title">
        Важное примечание
      </div>
      <div :class="classes.descriptionContent">
        <span :class="classes.descriptionContentText">
          {{ device.MarketDevice.Defects }}
        </span>
      </div>
    </div>
    <div
      v-if="device.MarketDevice?.DeviceDescription"
      :class="classes.description"
    >
      <div :class="classes.title">
        Дополнительно
      </div>
      <div :class="classes.descriptionContent">
        <span
          :class="classes.descriptionContentText"
          :style="{maxHeight: isShowAllText ? 'none' : '146px'}"
        >
          {{ device.MarketDevice.DeviceDescription }}
        </span>
        <Button
          intent="link"
          underline="on"
          @click="showAllText"
        >
          {{ isShowAllText ? "Скрыть" : "Читать полностью" }}
        </Button>
      </div>
    </div>
    <div :class="classes.specifications">
      <div :class="classes.title">
        Характеристики
      </div>
      <template
        v-for="devicePropertyItem in specifications"
        :key="devicePropertyItem.key"
      >
        <div
          v-if="!devicePropertyItem.isHidden"
          :class="classes.item"
        >
          <div :class="classes.itemName">
            {{ devicePropertyItem.name }}:
          </div>
          <div
            :class="classes.itemValue"
          >
            <template v-if="devicePropertyItem.value">
              {{ devicePropertyItem.value }}
            </template>
            <template v-if="devicePropertyItem.key === DEVICE_PROPERTY_KEYS.GARANTEE">
              <Icon
                name="common/check-rounded"
                width="19"
                height="19"
                :class-name="classes.itemValueIcon"
              />
              <QuestionTooltip
                v-if="!isNewDevice"
                title="Гарантия 1 год"
              >
                В течение года, предварительно позвонив на нашу горячую линию,
                вы можете обратиться в наиболее удобный салон Pedant.ru в вашем городе с любыми вопросами,
                которые касаются гарантийного обслуживания и эксплуатации приобретенного у нас устройства!
              </QuestionTooltip>
            </template>
            <QuestionTooltip
              v-else-if="devicePropertyItem.key === DEVICE_PROPERTY_KEYS.IMEI"
              title="IMEI"
            >
              Все устройства оригинальные.
            </QuestionTooltip>
          </div>
        </div>
      </template>
      <Button
        v-if="device.Attributes.length"
        intent="link"
        underline="on"
        class="mb-[25px]"
        @click="showAllSpecifications"
      >
        Все характеристики
      </Button>
    </div>
  </div>
</template>
