<script setup lang="ts">
import { Modal, Button, Icon } from "shared/ui"
import { storeToRefs } from "pinia"
import { useCitiesStore, useServiceCentersStore } from "stores"
import { declension } from "shared/lib"
import { type ServiceCenter } from "shared/api"
import { useBreakpoints } from "@vueuse/core"

const breakpoints = useBreakpoints({ md: 768 })
const mdAndLarger  = breakpoints.greaterOrEqual("md")

const citiesStore = useCitiesStore()
const { current: currentCity } = storeToRefs(citiesStore)

const serviceCentersStore = useServiceCentersStore()
const { list: serviceCenters } = storeToRefs(serviceCentersStore)

const emit = defineEmits(["serviceCenterSelected", "showCities"])

const showInModalServiceCenter = (serviceCenter: ServiceCenter) => {
  serviceCentersStore.shownInModal = serviceCenter
  emit("serviceCenterSelected")
}

const changeCity = () => {
  emit("showCities")
}
</script>

<template>
  <Modal
    :to-bottom="!mdAndLarger"
    content-transition="vfm-slide-up"
  >
    <div class="min-w-full p-4 md:min-w-[303px] md:pr-[30px] pb-5">
      <div
        v-if="currentCity"
        class="text-[20px]/[30px] mb-5"
      >
        {{ currentCity.services_count }}
        {{ declension(currentCity.services_count, ["филиал", "филиала", "филиалов"]) }}
        в
        <Button
          intent="link"
          underline="on"
          class="!inline !w-auto"
          @click="changeCity"
        >
          г. {{ currentCity.name }}
        </Button>
      </div>
      <div class="grid gap-5 max-h-[50vh] py-1 overflow-auto">
        <div
          v-for="(serviceCenter, index) in serviceCenters"
          :key="index"
          class="flex"
        >
          <Icon
            name="common/location"
            width="11"
            height="11"
            class-name="!text-yellow mt-[5px] mr-[15px]"
          />
          <div class="text-[14px]/[21px]">
            <div>
              {{ serviceCenter.anchor }}
            </div>
            <Button
              intent="link"
              underline="on"
              class="text-ghost"
              @click="showInModalServiceCenter(serviceCenter)"
            >
              Подробнее
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
