const wrapper = [
  "bg-bg-main-primary",
  "rounded-xl",
  "p-6",
  "md:p-[30px]"
]

const location = [
  "border-b",
  "border-b-solid",
  "border-b-border-primary",
  "pb-4",
  "md:pb-0",
  "md:border-0"
]

const locationIcon = [
  "!text-icon-accent",
  "mr-1"
]

const locationCity = [
  "flex",
  "items-center",
  "flex-wrap",
  "mb-2"
]

const locationText = "leading-[21px]"

const button = [
  "font-bold",
  "!underline",
  "!underline-offset-4"
]

const title = [
  "text-[24px]/[31px]",
  "font-bold",
  "text-primary",
  "mb-2.5"
]

const delivery = [
  "border-t",
  "border-t-solid",
  "border-t-border-primary",
  "pt-4"
]

const deliveryText = "leading-[21px]"

export default {
  wrapper,
  location,
  locationIcon,
  locationCity,
  locationText,
  button,
  title,
  delivery,
  deliveryText
}
