const wrapper = [
  "grid",
  "grid-cols-[1fr_1fr]",
  "gap-2.5",
  "mt-3",
  "md:flex-col-reverse"
]

const image = "w-full"

const preview = "order-2"

const text = "order-3"

const textBottom = "order-1"

const title = [
  "font-bold",
  "text-primary",
  "leading-none",
  "xl:text-[24px]"
]

const titleSmall = "xl:!text-[16px]/[19px]"

const description = [
  "text-[10px]",
  "xl:text-[16px]",
  "xl:text-primary"
]

const descriptionSmall = "xl:!text-[14px]/[16px]"

export default {
  wrapper,
  image,
  preview,
  text,
  textBottom,
  title,
  titleSmall,
  description,
  descriptionSmall
}
