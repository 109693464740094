<script setup lang="ts">
import { PropType, computed } from "vue"
import { Device } from "shared/api"
import { Stopper } from "shared/ui"
import classes from "./classes"
import { checkIsNew } from "shared/lib"
import { STOPPER_COLORS } from "shared/constants"
import { StopperProps } from "shared/ui/stopper/classes"

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  },
  horizontalDirection: {
    type: Boolean,
    default: false
  }
})

const isNewDevice = computed(() => checkIsNew(props.device))

const isBadgeExists = computed(() => {
  return isNewDevice.value ||
    props.device.Badges.WithoutRepair || props.device.BatteryVolume ||
    props.device.Badges.Cheapest || props.device.Badges.Hit
})
</script>

<template>
  <div
    v-if="isBadgeExists"
    :class="[
      classes.wrapper,
      horizontalDirection ? `${classes.wrapperColumn} scrollbar-fix` : ''
    ]"
  >
    <Stopper
      v-if="isNewDevice"
      :color="STOPPER_COLORS.BLUE as StopperProps['color']"
      icon-name="stoppers/star"
      icon-class-name="me-1"
      :icon-width="10"
      :icon-height="9"
      text="Новый"
    />
    <Stopper
      v-if="device.Badges.WithoutRepair"
      :color="STOPPER_COLORS.GREEN as StopperProps['color']"
      icon-name="stoppers/gear"
      icon-class-name="me-2"
      :icon-width="10"
      :icon-height="10"
      text="Без ремонта"
    />
    <Stopper
      v-if="device.Badges.Cheapest"
      :color="STOPPER_COLORS.RED as StopperProps['color']"
      icon-name="stoppers/coins"
      icon-class-name="me-2"
      :icon-width="12"
      :icon-height="13"
      text="Лучшая цена"
    />
    <Stopper
      v-if="device.Badges.Hit"
      :color="STOPPER_COLORS.RED as StopperProps['color']"
      icon-name="stoppers/fire"
      icon-class-name="me-2"
      :icon-width="11"
      :icon-height="13"
      text="Хит продаж"
    />
  </div>
</template>
