<script setup lang="ts">
import classes from "./classes"
import { computed, PropType, onMounted } from "vue"
import { Device } from "shared/api"
import { pageTitleLargeStyles } from "shared/lib"
import { useRoute } from "vue-router"
import { CatalogDeviceMediaGallery, CatalogDeviceSubscriptionCard, CatalogDeviceBuyCard, CatalogDeviceDeliveryCard } from "widgets"
import { useAbStore, useCatalogDeviceStore, useUserStore } from "stores"
import { storeToRefs } from "pinia"
import { EMIT_MODAL_FUNCTION_NAMES } from "shared/constants"
import { DeviceGrid } from "entities/device"

const route = useRoute()
const catalogDeviceStore = useCatalogDeviceStore()
const userStore = useUserStore()

const { isNew: isNewDevice } = storeToRefs(catalogDeviceStore)

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  },
  monthlyPayment: {
    type: Number,
    required: false,
    default: 0
  },
  months: {
    type: Number,
    required: false,
    default: 0
  }
})

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_CITIES,
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTERS,
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SPECIFICATIONS
])

const vendorCode = computed(() => {
  const id = route.params.id
  return id.slice(id.length - 6)
})

const abStore = useAbStore()
const { isPM309VariantA } = storeToRefs(abStore)

onMounted(() => {
  userStore.addViewedDevice(props.device)
})
</script>

<template>
  <div :class="[classes.wrapper, 'container']">
    <div :class="classes.header">
      <div :class="[classes.title, pageTitleLargeStyles]">
        {{ device.FullName }}
      </div>
      <div :class="classes.vendorCode">
        Артикул: {{ vendorCode }}
      </div>
    </div>
    <div :class="classes.sliderWrapper">
      <CatalogDeviceMediaGallery :device="device" />
    </div>
    <div :class="classes.infoWrapper">
      <DeviceGrid
        :device="device"
        :has-filters="true"
        @open-modal-show-specifications="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SPECIFICATIONS)"
      />
    </div>
    <div :class="classes.actionWrapper">
      <CatalogDeviceSubscriptionCard
        v-if="!isNewDevice && monthlyPayment"
        :device="device"
        :monthly-payment="monthlyPayment"
      />
      <CatalogDeviceBuyCard
        v-if="isNewDevice || isPM309VariantA"
        :device="device"
      />
      <CatalogDeviceDeliveryCard
        :device="device"
        @open-modal-show-cities="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_CITIES)"
        @open-modal-show-service-centers="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTERS)"
      />
    </div>
  </div>
</template>
