import { cx } from "cva"

const content = cx([
  "grid",
  "md:grid-cols-[354px_auto]",
  "gap-8",
  "lg:gap-[65px]",
  "p-7",
  "md:pb-[48px]",
  "md:pt-[48px]",
  "md:pl-[56px]",
  "md:pr-[56px]",
  "bg-bg-main-primary",
  "rounded-[12px]",
  "md:rounded-[24px]"
])

const galleryTitle = cx([
  "text-[21px]/[25px]",
  "font-bold",
  "text-primary",
  "mb-6",
  "md:text-[36px]/[46px]"
])

const galleryAssessment = cx([
  "grid",
  "grid-areas-reviews-gallery-assessment",
  "grid-cols-[auto_1fr]",
  "gap-x-4",
  "mb-4",
  "md:mb-9",
  "md:gap-y-2"
])

const galleryAssessmentNumber = cx([
  "grid-in-number",
  "text-[54px]/[59px]",
  "md:text-[64px]/[70px]",
  "text-primary"
])

const galleryAssessmentStars = cx([
  "grid-in-stars",
  "grid",
  "grid-cols-[repeat(5,_auto)]",
  "gap-x-1",
  "mr-auto",
  "self-end",
  "text-[18px]/[0px]",
  "text-yellow"
])

const galleryAssessmentStatistics = cx([
  "grid-in-statistics",
  "text-[14px]",
  "text-secondary",
  "self-center",
  "md:text-[16px]/[19px]"
])

const galleryList = cx([
  "grid",
  "grid-cols-[repeat(4,_auto)]",
  "justify-between",
  "mb-4",
  "md:grid-cols-[repeat(4,_1fr)]",
  "md:gap-x-4",
  "md:gap-y-6",
  "md:mb-0"
])

const galleryItem = cx([
  "relative",
  "w-[64px]",
  "h-[64px]",
  "rounded-xl",
  "bg-center",
  "bg-cover",
  "cursor-zoom-in",
  "md:w-[72px]",
  "md:h-[72px]"
])

const galleryItemLink = cx([
  "block",
  "w-full",
  "h-full"
])
const reviews = cx([
  "md:flex",
  "flex-col",
  "justify-between"
])
const pagination = cx([
  "ml-auto",
  "mr-auto",
  "md:ml-[0px]"
])
export default {
  content,
  galleryTitle,
  galleryAssessment,
  galleryAssessmentNumber,
  galleryAssessmentStars,
  galleryAssessmentStatistics,
  galleryList,
  galleryItem,
  galleryItemLink,
  reviews,
  pagination
}
