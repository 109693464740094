import { cx } from "cva"

const root = cx([
  "text-center"
])

const title = cx([
  "text-[21px]/[120%]",
  "font-bold",
  "text-primary",
  "mb-4",
  "md:text-[36px]/[130%]"
])

const blockquote = cx([
  "italic",
  "text-[14px]/[120%]",
  "mb-4",
  "before:block",
  "before:w-[18px]",
  "before:h-[18px]",
  "before:bg-[url('/images/company/entypo_quote.svg')]",
  "before:bg-cover",
  "before:my-4",
  "before:mx-auto",
  "md:max-w-[1112px]",
  "md:my-0",
  "md:mx-auto",
  "md:mb-4",
  "md:text-[24px]",
  "md:before:w-[36px]",
  "md:before:h-[36px]"
])

const footnote = cx([
  "pt-4",
  "border-t",
  "border-t-solid",
  "border-t-border-primary",
  "text-[10px]/[120%]",
  "text-ghost",
  "md:text-[16px]",
  "[&>p]:mb-[5px]"
])

export default {
  root,
  title,
  blockquote,
  footnote
}
