import { cx } from "cva"

const root = cx([
  "flex",
  "flex-col",
  "items-center",
  "py-6",
  "px-4",
  "bg-bg-main-thirdly"
])

const title = cx([
  "text-[21px]/[120%]",
  "font-bold",
  "text-primary",
  "mb-2",
  "md:text-[36px]"
])

const subtitle = cx([
  "text-[14px]/[120%]",
  "text-center",
  "mb-6",
  "md:text-[16px]"
])

const contacts = cx([
  "flex",
  "text-[16px]/[120%]",
  "md:text-[24px]"
])

const contactsItem = cx([
  "font-bold",
  "no-underline",
  "transition",
  "duration-300",
  "text-accent",
  "whitespace-nowrap",
  "hover:text-accent-hover",
  "hover:underline",
  "active:text-accent-active"
])

const contactsItemEmail = cx([
  "ml-9"
])

export default {
  root,
  title,
  subtitle,
  contacts,
  contactsItem,
  contactsItemEmail
}
