import { cx } from "cva"

const root = cx([
  "mt-[5px]",
  "mx-auto",
  "mb-10",
  "lg:mt-[30px]",
  "lg:mb-[50px]"
])

const wrapper = cx([
  "grid",
  "lg:grid-cols-[241px_1fr]",
  "lg:gap-5",
  "lg:items-start"
])

const listing = cx([
  "relative",
  "mt-0.5",
  "flex",
  "flex-col"
])

const listingGrid = cx([
  "grid",
  "grid-cols-2",
  "gap-4",
  "mt-5",
  "mb-2.5",
  "justify-between",
  "md:grid-cols-3",
  "md:gap-y-9",
  "md:gap-x-[18px]",
  "xl:grid-cols-4",
  "xl:mb-9"
])

const sortFilterWrapper = cx([
  "flex",
  "justify-between",
  "items-center"
])

const sortFilter = cx([
  "[&>select]:!outline-none",
  "[&>select]:!p-2",
  "[&>select]:!pr-[30px]",
  "[&>select]:!border-none",
  "after:!rotate-0",
  "after:!w-0",
  "after:!h-0",
  "after:!border-b-0",
  "after:!border-l-[5px]",
  "after:!border-l-transparent",
  "after:!border-r-[5px]",
  "after:!border-r-transparent",
  "after:!border-t-[6px]",
  "after:!border-t-primary",
  "after:!top-[calc(50%_-_2px)]",
  "after:!right-2.5"
])

export default {
  root,
  wrapper,
  listing,
  listingGrid,
  sortFilterWrapper,
  sortFilter
}
