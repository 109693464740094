import { cx } from "cva"
import { dotsAfter } from "shared/lib"

const list = cx([
  "grid",
  "grid-cols-[1fr]",
  "gap-x-[25px]",
  "gap-y-[5px]",
  "md:grid-cols-[repeat(2,_1fr)]"
])

const column = cx([
  "grid",
  "gap-y-[5px]"
])

const item = cx([
  "grid",
  "grid-cols-[auto_1fr_auto]"
])

const itemDots = cx([
  "relative",
  ...dotsAfter
])

const imgWrapper = cx([
  "flex",
  "justify-center",
  "items-start",
  "pt-[5px]"
])

export default {
  list,
  column,
  item,
  itemDots,
  imgWrapper
}
