export const getPhoneLink = () => "tel:88003013309"
export const getPhoneText = () => "8 (800) 301-33-09"
export const getEmailLink = () => "mailto:pr@pedant.ru"
export const getEmailText = () => "pr@pedant.ru"
export const getTelegramLink = (random: boolean = true) => {
  const defaultUsername = "PedantMarket_bot"
  const alternativeUsername = "Pedant_Market_bot"

  const username = random
    ? (Math.random() < 0.5 ? defaultUsername : alternativeUsername)
    : defaultUsername

  return `tg://resolve?domain=${username}`
}
export const getVkLink = () => "https://vk.me/pedant.market?ref=pm_vkbot_test"
export const getWhatsappLink = (text: string = "Здравствуйте! У меня вопрос") => `https://api.whatsapp.com/send/?phone=79279260969&text=${text}`
export const getRefundPageLink = () => "https://lk.pedant.market/refund"
