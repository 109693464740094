const steps = [
  "mb-2",
  "w-full"
]

const stepsItem = [
  "mb-2",
  "first:mb-3",
  "last:mb-0"
]

const stepsItemNumber = [
  "inline-block",
  "w-[20px]",
  "h-[20px]",
  "mr-2",
  "text-[14px]/[20px]",
  "font-bold",
  "text-center",
  "rounded-[100px]",
  "bg-bg-dark-blue-disable"
]

const periods = [
  "w-full",
  "md:max-w-[256px]"
]

export default {
  steps,
  stepsItem,
  stepsItemNumber,
  periods
}
