import { cx } from "cva"

const root = cx([
  "grid",
  "grid-cols-[1fr]",
  "grid-rows-[repeat(2,_auto)]",
  "gap-4",
  "lg:grid-rows-[1fr]",
  "lg:grid-cols-[repeat(2,_auto)]"
])

const reviews = cx([
  "grid",
  "grid-cols-[repeat(3,_1fr)]",
  "gap-[7px]",
  "md:gap-4",
  "md:grid-cols-[1fr]",
  "md:grid-rows-[repeat(3,_auto)]"
])

const review = cx([
  "py-[5px]",
  "px-[10px]",
  "rounded-md",
  "bg-bg-main-thirdly",
  "lg:flex",
  "lg:reviews-center",
  "lg:justify-center",
  "lg:w-[416px]",
  "lg:py-6",
  "lg:px-9",
  "lg:rounded-xl"
])

const reviewTotal = cx([
  "hidden",
  "text-primary",
  "lg:block",
  "lg:text-[96px]/[1]"
])

const reviewContent = cx([
  "lg:grid",
  "lg:grid-cols-[1fr]",
  "lg:grid-rows-[repeat(3,_auto)]",
  "lg:gap-[7px]",
  "lg:ml-3.5"
])

const reviewName = cx([
  "flex",
  "justify-between",
  "reviews-end",
  "whitespace-nowrap"
])

const reviewNameImg = cx([
  "h-[14px]",
  "2xs-sm:h-[18px]",
  "lg:!h-[34px]"
])

const reviewRating = cx([
  "flex",
  "items-center",
  "ml-[5px]",
  "text-[12px]/[130%]",
  "font-semibold",
  "whitespace-nowrap",
  "text-yellow",
  "lg:hidden"
])

const reviewStars = cx([
  "hidden",
  "lg:flex",
  "lg:text-[16px]",
  "lg:text-yellow"
])

const reviewDescr = cx([
  "text-[10px]/[130%]",
  "lg:text-[16px]"
])

const map = cx([
  "flex",
  "justify-center"
])

const mapImg = cx([
  "object-contain"
])

export default {
  root,
  reviews,
  review,
  reviewTotal,
  reviewContent,
  reviewName,
  reviewNameImg,
  reviewRating,
  reviewStars,
  reviewDescr,
  map,
  mapImg
}
