import { cva, VariantProps } from "cva"
import { STOPPER_COLORS } from "shared/constants"

const base = [
  "flex",
  "items-center",
  "min-w-max",
  "py-[3px]",
  "px-2",
  "rounded-lg",
  "font-bold",
  "text-[10px]/[11px]",
  "text-primary-on-color"
]

const stopper = cva(base, {
  variants: {
    color: {
      [STOPPER_COLORS.RED]: "bg-bg-red-error",
      [STOPPER_COLORS.GREEN]: "bg-bg-green-success",
      [STOPPER_COLORS.BLUE]: "bg-main-blue-1000",
      [STOPPER_COLORS.WHITE]: ["bg-bg-main-secondary", "!text-secondary"],
      [STOPPER_COLORS.YELLOW]: ["bg-bg-yellow-yellow", "!text-primary"]
    }
  }
})

export type StopperProps = VariantProps<typeof stopper>

export default {
  stopper
}
