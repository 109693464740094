const wrapper = [
  "grid",
  "gap-1",
  "grid-flow-row",
  "auto-rows-fr",
  "justify-start",
  "justify-items-start"
]

const wrapperColumn = [
  "w-full",
  "grid-flow-column",
  "overflow-x-scroll"
]

export default {
  wrapper,
  wrapperColumn
}
