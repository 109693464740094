import { acceptHMRUpdate, defineStore } from "pinia"
import { computed, ref } from "vue"

interface CatalogStory {
  id: number,
  title: string,
  icon: string,
  storiesGroup: Array<{
    itemId: number,
    isViewed: boolean,
  }>
}

export const useCatalogStoriesStore = defineStore("catalog-stories", {
  state: () => {
    const stories = ref<CatalogStory[]>([
      {
        id: 1,
        title: "100% оригинал",
        icon: "brands/apple",
        storiesGroup: [
          {
            itemId: 1,
            isViewed: false
          }
        ]
      },
      {
        id: 2,
        title: "Подписка выгоднее",
        icon: "common/thumbs-up",
        storiesGroup: [
          {
            itemId: 2,
            isViewed: false
          },
          {
            itemId: 3,
            isViewed: false
          },
          {
            itemId: 4,
            isViewed: false
          }
        ]
      },
      {
        id: 3,
        title: "Гарантия<br/>на весь срок",
        icon: "common/shield",
        storiesGroup: [
          {
            itemId: 5,
            isViewed: false
          }
        ]
      }
    ])

    const viewedStoriesIds = computed(() => {
      return stories.value
        .filter(story => {
          return story.storiesGroup.every(storyItem => storyItem.isViewed)
        })
        .map(story => story.id)
    })

    const storyIndex = ref<number | null>(null)
    const isStoriesActive = ref<boolean>(false)

    const setStoryIsViewed = (id: number, index: number) => {
      const story = stories.value.find(currentStory => currentStory.id === id)
      if (story) {
        story.storiesGroup[index].isViewed = true
      }
    }

    return {
      stories,
      storyIndex,
      isStoriesActive,
      viewedStoriesIds,
      setStoryIsViewed
    }
  },
  persist: {
    storage: localStorage,
    paths: ["stories"]
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCatalogStoriesStore, import.meta.hot))
}
