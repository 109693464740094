import { cx } from "cva"
import { socialButtonWaStyles } from "shared/lib"

const root = cx([
  "md:relative",
  "grid",
  "bg-bg-main-primary",
  "mb-10",
  "!py-[20px]",
  "md:!py-[30px]",
  "md:mt-8",
  "md:mb-12"
])

const inner = cx([
  "container"
])

const title = cx([
  "text-[21px]/[25px]",
  "lg:text-[36px]/[43px]",
  "font-bold",
  "text-primary",
  "!mb-[24px]",
  "lg:mb-2.5"
])

const description = cx([
  "mb-8"
])

const row = cx([
  "flex",
  "gap-x-24",
  "flex-wrap"
])

const phone = cx([
  "flex",
  "flex-col",
  "mb-[10px]",
  "sm:basis-full",
  "md:basis-0"
])

const socials = cx([
  "flex",
  "gap-x-5"
])

const numberLink = cx([
  "text-[16px]",
  "text-blue-900",
  "whitespace-nowrap",
  "cursor-pointer",
  "underline",
  "hover:opacity-70",
  "transition",
  "duration-300",
  "mt-0.5"
])

const socialButtonWhatsapp = cx([
  "flex",
  "items-center",
  "justify-center",
  "gap-2",
  "w-[140px]",
  "h-[33px]",
  "md:w-[162px]",
  "md:h-[33px]",
  "rounded-lg",
  "mt-0.5",
  ...socialButtonWaStyles
])

const socialButtonTelegram = cx([
  "flex",
  "items-center",
  "justify-center",
  "gap-2",
  "w-[140px]",
  "h-[33px]",
  "md:w-[162px]",
  "md:h-[33px]",
  "rounded-lg",
  "!bg-main-blue-1000",
  "text-primary-on-color",
  "mt-0.5"
])

export default {
  root,
  inner,
  title,
  description,
  row,
  phone,
  socials,
  socialButtonWhatsapp,
  socialButtonTelegram,
  numberLink
}
