<script setup lang="ts">
import classes from "./classes"
import { cva, type VariantProps } from "cva"
import { Icon } from ".."
import { computed } from "vue"
import { vMaska } from "maska"

const input = cva([], {
  variants: {
    intent: {
      primary: classes.primary
    },
    type: {
      input: classes.input,
      search: classes.searchInput
    }
  }
})

type InputTextProps = VariantProps<typeof input>

interface Props {
  modelValue?: string,
  intent: InputTextProps["intent"],
  type: InputTextProps["type"],
  id?: string,
  minlength?: number,
  maxlength?: number,
  placeholder?: string,
  error?: string,
  className?: string,
  inputType?: "text" | "email",
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search", // HTMLAttributes.inputMode
  required?: boolean,
  mask?: string
  readonly?: boolean,
  autocomplete?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    modelValue: "",
    intent: "primary",
    type: "input",
    id: "",
    minlength: 0,
    maxlength: undefined,
    placeholder: "",
    error: "",
    className: "",
    inputType: "text",
    inputMode: "text",
    required: false,
    mask: "",
    readonly: false,
    autocomplete: ""
  }
)

const emit = defineEmits(["update:modelValue"])

const inputValue = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit("update:modelValue", value)
  }
})
</script>

<template>
  <div :class="[classes.wrapper, error ? classes.wrapperInvalid : '']">
    <input
      :id="id"
      v-model="inputValue"
      v-maska
      :class="[input({ intent, type }), error && !readonly ? classes.invalidInput : '', readonly ? classes.readonlyInput : '', className]"
      :type="inputType"
      :minlength="minlength"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :inputmode="inputMode"
      :required="required"
      :readonly="readonly"
      :data-maska="mask"
      :autocomplete="autocomplete"
      spellcheck="false"
      @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    >
    <label
      v-if="id"
      :class="classes.label"
      :for="id"
    >
      {{ placeholder }}
    </label>
    <div
      v-show="error && !readonly"
      :class="classes.invalidText"
    >
      {{ error }}
    </div>
    <Icon
      v-if="error && !readonly"
      :class="classes.icon"
      name="common/input-error"
      width="20"
      height="20"
    />
  </div>
</template>
