import { cx } from "cva"

const wrapperStyles = cx([
  "mt-5",
  "mb-10",
  "leading-[1.3]",
  "lg:mt-[30px]",
  "lg:mb-[60px]"
])

const titleStyles = cx([
  "text-[21px]",
  "font-bold",
  "text-primary",
  "mb-[15px]",
  "lg:text-[36px]",
  "lg:mb-[25px]"
])

const itemsStyles = cx([
  "grid",
  "gap-5",
  "grid-cols-1",
  "lg:grid-cols-2"
])

const itemStyles = cx([
  "grid",
  "gap-[15px]",
  "grid-cols-[70px_1fr]",
  "p-[15px]",
  "rounded-[5px]",
  "md:grid-cols-[170px_1fr]",
  "lg:gap-[30px]",
  "lg:py-[25px]",
  "lg:px-10"
])

const unavailableItemStyles = cx([
  "opacity-60",
  "group",
  "is-unavailable"
])

const leftItemsStyles = cx([
  "flex",
  "items-center"
])

const disabledItemLinkStyles = cx([
  "pointer-events-none"
])

const rightItemStyles = cx([
  "grid",
  "grid-rows-[auto_auto_1fr]",
  "gap-3",
  "group-[.is-unavailable]:grid-rows-[1fr_auto]"
])

const itemTitleStyles = cx([
  "!text-[24px]/[1.3]",
  "font-bold",
  "!text-primary"
])

const modelsStyles = cx([
  "grid",
  "gap-2",
  "group-[.is-unavailable]:hidden"
])

const linkStyles = cx([
  "!inline-block",
  "!leading-[1.3]"
])

const allLinkStyles = cx([
  "flex",
  "!items-end",
  "!w-fit",
  "!leading-[1.3]",
  "group-[.is-unavailable]:hidden"
])

const saleSoonStyles = cx([
  "hidden",
  "w-fit",
  "py-2",
  "px-3",
  "bg-icon-ghost",
  "rounded-[5px]",
  "group-[.is-unavailable]:block"
])

export default {
  wrapperStyles,
  titleStyles,
  itemsStyles,
  itemStyles,
  unavailableItemStyles,
  leftItemsStyles,
  linkStyles,
  disabledItemLinkStyles,
  rightItemStyles,
  itemTitleStyles,
  modelsStyles,
  allLinkStyles,
  saleSoonStyles
}
