const wrapper = [
  "grid",
  "gap-4",
  "mt-[60px]",
  "mb-9",
  "md:grid-flow-col",
  "md:my-0"
]

const item = [
  "pl-2",
  "border-l-[3px]",
  "border-l-solid",
  "border-l-border-yellow",
  "md:mt-20",
  "md:mx-0",
  "md:mb-9",
  "md:pl-[15px]"
]

const itemTitle = [
  "font-bold",
  "text-primary"
]

export default {
  wrapper,
  item,
  itemTitle
}
