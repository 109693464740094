const wrapper = [
  "inline-flex",
  "items-center"
]

const dropdown = "ml-2"

const dropdownPopper = [
  "max-w-[300px]",
  "mt-[22px]",
  "mb-[30px]",
  "mx-6",
  "md:max-w-[448px]",
  "[&>p]:mb-4"
]

export default {
  wrapper,
  dropdown,
  dropdownPopper
}
