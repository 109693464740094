import { cx, cva, type VariantProps } from "cva"

const base = cx([
  "relative",
  "inline-block",
  "text-[14px]",
  "lg:text-[16px]",
  "after:absolute",
  "after:w-2",
  "after:h-2",
  "after:top-[calc(50%_-_6px)]",
  "after:right-5",
  "after:border-t-0",
  "after:border-l-0",
  "after:border-r-2",
  "after:border-b-2",
  "after:border-solid",
  "after:border-icon-secondary",
  "after:rotate-45",
  "after:pointer-events-none"
])

const baseLarge = cx([
  "group",
  "is-large"
])

const baseSmall = cx([
  "group",
  "is-small"
])

const filtersSelect = cva(base, {
  variants: {
    size: {
      ["large"]: baseLarge,
      ["small"]: baseSmall
    }
  }
})

const input = cx([
  "w-full",
  "border-2",
  "border-solid",
  "border-border-primary",
  "rounded-lg",
  "appearance-none",
  "py-3",
  "pr-[50px]",
  "pl-5",
  "group-[.is-large]:pt-2.5",
  "group-[.is-large]:pr-[50px]",
  "group-[.is-large]:pb-2.5",
  "group-[.is-large]:pl-5",
  "group-[.is-small]:py-2",
  "group-[.is-small]:px-4",
  "group-[.is-small]:leading-none",
  "bg-bg-main-secondary"
])

export type FiltersSelectProps = VariantProps<typeof filtersSelect>

export default {
  filtersSelect,
  input
}
