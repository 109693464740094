const wrapperFullWidth = [
  "group",
  "is-full-width"
]

const list = [
  "flex",
  "border-2",
  "border-solid",
  "border-border-primary",
  "rounded-3xl",
  "overflow-hidden",
  "w-max",
  "bg-bg-main-secondary",
  "mb-4",
  "group-[.is-full-width]:w-full"
]

const tab = [
  "py-2",
  "px-2.5",
  "cursor-pointer",
  "relative",
  "md:whitespace-nowrap",
  "group-[.is-full-width]:flex-1",
  "group-[.is-full-width]:text-center",
  "group-[.is-full-width]:flex",
  "group-[.is-full-width]:justify-center",
  "group-[.is-full-width]:items-center"
]

const tabActive = [
  "bg-bg-blue-accent",
  "text-primary-on-color",
  "rounded-[5px]"
]

export default {
  wrapperFullWidth,
  list,
  tab,
  tabActive
}
