<script setup lang="ts">
import classes from "./classes"
import { YANDEX_METRIKA_GOALS, emitMixpanelEventCardSubscriptionVideoPreviewClicked, emitYandexMetrika } from "shared/lib"
import { PropType } from "vue"
import { Device } from "shared/api"
import GLightbox from "glightbox"

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: false
  },
  size: {
    type: String,
    default: "normal" // or 'small'
  },
  textPosition: {
    type: String,
    default: "top" // or 'bottom'
  },
  textFullWidth: {
    type: Boolean,
    default: false
  }
})

const openVideo = () => {
  const myGallery = GLightbox({
    elements: [
      {
        "href": "https://youtu.be/V61A3rHMPG8"
      }
    ],
    autoplayVideos: true
  })

  myGallery.open()

  if (props.device) {
    emitMixpanelEventCardSubscriptionVideoPreviewClicked(props.device)
    emitYandexMetrika(YANDEX_METRIKA_GOALS.VIDEO_O_PODPISKE_NA_STRANICE_TOVARA)
  }
}
</script>

<template>
  <div :class="classes.wrapper">
    <picture
      :class="classes.preview"
      @click="openVideo"
    >
      <source
        media="(max-width: 768px)"
        srcset="/images/catalog-device-subscription-video/video-preview.webp"
        width="284"
        height="163"
      >
      <source
        media="(max-width: 768px)"
        srcset="/images/catalog-device-subscription-video/video-preview.jpeg"
        width="284"
        height="163"
      >
      <source
        srcset="/images/catalog-device-subscription-video/video-preview-desktop.webp"
        width="648"
        height="365"
      >
      <img
        :class="classes.image"
        src="/images/catalog-device-subscription-video/video-preview-desktop.jpg"
        alt="Подробнее о подписке"
        width="648"
        height="365"
      >
    </picture>
    <div :class="[classes.text, textPosition === 'bottom' ? classes.textBottom : '']">
      <div :class="[classes.title, size === 'small' ? classes.titleSmall : '']">
        Рассказываем о <br
          v-if="!textFullWidth"
          class="md:hidden"
        >
        подписке за 1 минуту
      </div>
      <div :class="[classes.description, size === 'small' ? classes.descriptionSmall: '']">
        Популярная услуга теперь <br
          v-if="!textFullWidth"
          class="md:hidden"
        >
        доступна в Pedant.Market
      </div>
    </div>
  </div>
</template>
