const userSection = [
  "mb-9",
  "md:mb-20"
]

const userSectionTitle = [
  "text-[21px]/[120%]",
  "font-bold",
  "text-primary",
  "mb-6",
  "md:text-[36px]",
  "md:mb-9"
]

const userSectionNameGrid = [
  "grid",
  "gap-4",
  "mb-4",
  "md:gap-5",
  "md:grid-cols-[repeat(2,_1fr)]",
  "md:mb-6"
]

const userSectionContactGrid = [
  "grid",
  "gap-4",
  "md:grid-cols-[repeat(2,_1fr)]"
]

const userSectionContactInput = [
  "grid",
  "gap-1",
  "md:gap-2"
]

const userSectionContactNotice = [
  "text-[10px]/[120%]",
  "text-ghost",
  "md:text-[11px]"
]

const totalSection = [
  "py-6",
  "px-4",
  "shadow-xl",
  "rounded-xl",
  "md:p-9"
]

const totalSectionTitle = [
  "text-[21px]/[120%]",
  "font-bold",
  "text-primary",
  "mb-4",
  "md:text-[36px]",
  "md:mb-[33px]"
]

const totalSectionContent = [
  "grid",
  "grid-cols-[1fr]",
  "grid-rows-[repeat(4,_1fr)]",
  "gap-y-4",
  "md:grid-cols-[repeat(2,_1fr)]",
  "md:grid-rows-[repeat(2,_1fr)]",
  "md:gap-x-[60px]",
  "md:gap-y-6"
]

const totalSectionList = [
  "grid",
  "gap-1",
  "text-[14px]/[120%]",
  "md:gap-2",
  "md:pb-3",
  "md:text-[16px]"
]

const totalSectionItem = [
  "flex",
  "justify-between",
  "items-center",
  "w-full"
]

const totalSectionResult = [
  "flex",
  "justify-center",
  "items-center",
  "text-[16px]/[120%]",
  "font-bold",
  "text-primary",
  "md:text-[24px]"
]

const totalSectionItemValue = [
  "text-[16px]",
  "md:text-[36px]"
]

const totalSectionButton = [
  "h-[50px]",
  "self-center"
]

export default {
  userSection,
  userSectionTitle,
  userSectionNameGrid,
  userSectionContactGrid,
  userSectionContactInput,
  userSectionContactNotice,
  totalSection,
  totalSectionTitle,
  totalSectionContent,
  totalSectionList,
  totalSectionItem,
  totalSectionResult,
  totalSectionItemValue,
  totalSectionButton
}
