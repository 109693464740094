import { cx } from "cva"

const navigationStyles = cx([
  "backdrop-blur-xl",
  "bg-blend-multiply",
  "bg-bg-main-primary",
  "sticky",
  "z-[2]"
])

const wrapperStyles = cx([
  "w-full",
  "flex",
  "items-center",
  "py-[10px]",
  "whitespace-nowrap",
  "container",
  "justify-between",
  "md:justify-start"
])

const navigationItemStyles = cx([
  "md:mr-9",
  "last:mr-0",
  "md:last:ml-auto",
  "md:font-semibold",
  "text-primary",
  "hover:text-accent-hover",
  "transition",
  "duration-300"
])

const navigationItemDropdownStyles = cx([
  "mr-9",
  "last:mr-0",
  "last:ml-auto",
  "font-semibold",
  "text-primary",
  "transition",
  "duration-300"
])

export default {
  navigationStyles,
  wrapperStyles,
  navigationItemStyles,
  navigationItemDropdownStyles
}
