<!-- eslint-disable no-unused-vars -->
<script setup lang="ts">
import { storeToRefs } from "pinia"
import { Modal, Button, Icon } from "shared/ui"
import { useCitiesStore } from "stores"
import { useBreakpoints } from "@vueuse/core"

const breakpoints = useBreakpoints({ md: 768 })
const mdAndLarger  = breakpoints.greaterOrEqual("md")

const citiesStore = useCitiesStore()
const { current : currentCity } = storeToRefs(citiesStore)

const emit = defineEmits<{
  (e: "confirm"): void,
  (e: "changeCity"): void,
  (e: "closed"): void
}>()
</script>

<template>
  <Modal
    v-if="currentCity"
    :to-bottom="!mdAndLarger"
    @closed="emit('closed')"
  >
    <div class="min-w-max p-4 md:min-w-[428px] pr-[30px] pb-5 grid auto-cols-fr justify-items-center">
      <Icon
        name="common/location"
        width="54"
        height="54"
        class-name="!text-accent mb-4"
      />
      <p class="text-center mb-2.5">
        Ваш город <span class="inline-block w-full text-2xl/[36px] font-bold">{{ currentCity.name }}?</span>
      </p>
      <div class="grid gap-x-3 grid-cols-2 w-full">
        <Button
          intent="base-primary"
          size="middle"
          @click="emit('confirm')"
        >
          Да
        </Button>
        <Button
          intent="base-secondary"
          size="middle"
          @click="emit('changeCity')"
        >
          Выбрать другой
        </Button>
      </div>
    </div>
  </Modal>
</template>
