const button = [
  "rounded-lg",
  "!p-4",
  "leading-[16px]",
  "font-bold",
  "!w-full",
  "flex",
  "items-center",
  "justify-between",
  "bg-bg-main-thirdly",
  "bg-[url('/images/subscription-button/corner-left.png')]",
  "bg-no-repeat",
  "text-primary",
  "md:max-w-[560px]"
]

export default {
  button
}
