<script setup lang="ts">
import classes from "./classes"
import { PropType } from "vue"
import { Button, Stopper } from "shared/ui"
import { Device } from "shared/api"
import {
  YANDEX_METRIKA_GOALS,
  emitYandexMetrika,
  numberSpace,
  emitMixpanelEventCardSubscriptionMoreLinkClicked
} from "shared/lib"
import { useRouter } from "vue-router"
import { ROUTE_NAMES } from "shared/constants"
import { CatalogDeviceSubscriptionCardSteps } from "widgets"

defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  },
  monthlyPayment: {
    type: Number,
    required: false,
    default: 0
  }
})

const router = useRouter()

const redirectToSubscribe = (device: Device) => {
  if (device) {
    emitMixpanelEventCardSubscriptionMoreLinkClicked(device)
    emitYandexMetrika(YANDEX_METRIKA_GOALS.CARD_SUBSCRIPTION_MORE_LINK_CLICKED)
  }

  router.push({ name: ROUTE_NAMES.SUBSCRIPTION })
}
</script>

<template>
  <div :class="classes.wrapper">
    <div :class="classes.header">
      <div :class="classes.title">
        По подписке
      </div>
      <Stopper
        v-if="device.Badges.IsSale"
        color="red"
        icon-name="stoppers/sale"
        icon-class-name="me-2"
        :icon-width="11"
        :icon-height="11"
        text="Скидка"
        :class="['py-[4px]', 'text-[10px]', 'w-fit', 'mb-1']"
      />
      <Stopper
        v-else
        :class="classes.stopper"
        color="red"
        text="Выгоднее рассрочки"
      />
    </div>
    <CatalogDeviceSubscriptionCardSteps />
    <div
      v-if="monthlyPayment"
      :class="classes.total"
    >
      <span :class="classes.titleBig">
        {{ numberSpace(monthlyPayment) }} ₽/мес
      </span>
    </div>
    <Button
      intent="base-primary"
      size="middle"
      :class="classes.button"
      @click="redirectToSubscribe(device)"
    >
      Подробнее о подписке
    </Button>
  </div>
</template>
