import { cx } from "cva"

const root = cx([
  "flex",
  "flex-wrap",
  "my-3.5",
  "text-[14px]",
  "text-ghost",
  "overflow-x-scroll",
  "no-scrollbar",
  "md:text-[16px]",
  "md:my-6"
])

const list = cx([
  "flex",
  "flex-nowrap",
  "bg-transparent",
  "mb-0"
])

const item = cx([
  "flex",
  "items-center",
  "justify-center",
  "whitespace-nowrap"
])

const text = cx([
  "cursor-pointer"
])

const icon = cx([
  "mx-3"
])

const link = cx([
  "transition",
  "duration-300",
  "hover:text-accent-hover",
  "active:text-accent-click"
])

export default {
  root,
  list,
  item,
  text,
  icon,
  link
}
