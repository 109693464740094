import { paymentCardWrapper }  from "shared/lib"

const wrapper = [
  ...paymentCardWrapper,
  "grid",
  "grid-rows-[repeat(2,_auto)]",
  "gap-2",
  "hover:bg-bg-bg-main-primary",
  "hover:shadow-none",
  "transition",
  "duration-300"
]

const discount = [
  "flex",
  "items-center",
  "text-[11px]/[16.5px]"
]

const discountPercent = [
  "py-px",
  "px-[7px]",
  "mr-[5px]",
  "text-[10px]/[15px]",
  "font-bold",
  "text-primary",
  "rounded-lg",
  "bg-bg-yellow-yellow"
]

const action = [
  "w-full",
  "grid",
  "grid-cols-[1fr_auto]",
  "items-center"
]

const actionInfo = [
  "grid",
  "grid-rows-[repeat(2,_auto)]",
  "gap-1"
]

const discountTitle = [
  "text-[14px]/[16px]",
  "font-bold",
  "text-primary"
]

const price = [
  "flex",
  "items-end"
]

const priceNewPrice = [
  "text-[32px]/[35px]",
  "font-bold",
  "text-primary",
  "mr-2",
  "md:text-[24px]/[28px]"
]

const priceOldPrice = "line-through"

export default {
  wrapper,
  discount,
  discountPercent,
  action,
  actionInfo,
  discountTitle,
  price,
  priceNewPrice,
  priceOldPrice
}
