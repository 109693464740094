import { cx } from "cva"

const wrapper = cx([
  "relative",
  "grid",
  "grid-areas-trade-in-section-sm",
  "gap-6",
  "p-6",
  "rounded-[18px]",
  "bg-gradient-trade-in-section",
  "text-primary-on-color",
  "cursor-pointer",
  "xl:grid-areas-trade-in-section-lg",
  "xl:gap-x-7",
  "xl:gap-y-4",
  "xl:grid-rows-trade-in-section-lg",
  "xl:h-[233px]",
  "xl:overflow-hidden",
  "xl:py-[30px]",
  "xl:px-[56px]"
])

const title = cx([
  "grid-in-title",
  "text-center",
  "xl:text-left"
])

const titleText = cx([
  "text-[21px]/[120%]",
  "xl:text-[36px]/[110%]",
  "font-bold"
])

const devices = cx([
  "grid",
  "grid-in-devices",
  "grid-rows-[repeat(2,auto)]",
  "gap-[25px]",
  "xl:grid-rows-none",
  "xl:grid-cols-[repeat(2,auto)]",
  "xl:gap-[46px]",
  "xl:items-center"
])

const device = cx([
  "grid",
  "grid-cols-[auto_1fr]",
  "gap-[20px]",
  "my-0",
  "mx-auto",
  "xl:gap-6",
  "xl:m-0"
])

const devicePicture = cx([
  "w-[70px]",
  "xl:w-[86px]",
  "h-[auto]"
])

const deviceInfo = cx([
  "grid",
  "grid-rows-[repeat(3,_auto)]",
  "gap-2",
  "leading-[120%]",
  "xl:items-start",
  "xl:grid-rows-[auto_auto_1fr]",
  "xl:gap-[11px]"
])

const deviceInfoName = cx([
  "text-sm/[21px]",
  "md:text-base/[120%]",
  "lg:leading-[130%]"
])

const deviceInfoPrice = cx([
  "grid",
  "gap-1.5",
  "grid-cols-[auto_1fr]",
  "items-center",
  "text-[21px]",
  "md:text-[24px]",
  "font-bold",
  "lg:leading-[130%]"
])

const deviceInfoButton = cx([
  "!justify-start",
  "!text-sm/[21px]",
  "!text-yellow",
  "md:!text-base/[120%]",
  "lg:!leading-[130%]"
])

const buttonWrapper = cx([
  "grid-in-button",
  "md:my-0",
  "md:mx-auto",
  "xl:m-0"
])

const button = cx([
  "py-[17px]",
  "px-5",
  "font-bold",
  "xl:py-[13px]",
  "xl:px-[33px]",
  "md:max-w-[226px]"
])

const devicesGrid = cx([
  "grid",
  "grid-cols-[repeat(4,_auto)]",
  "grid-rows-[1fr]",
  "justify-between",
  "md:gap-x-[27px]",
  "md:justify-center",
  "xl:gap-x-[46px]"
])

const devicesGridItem = cx([
  "flex",
  "flex-col",
  "justify-end",
  "items-center",
  "text-[10px]/[12px]",
  "md:text-[16px]/[120%]"
])

const devicesGridItemImg = cx([
  "w-[47px]",
  "mb-2",
  "xl:w-[68px]",
  "xl:mb-1.5"
])

const devicesGridItemImgActive = cx([
  "box-content",
  "p-1",
  "rounded-[7px]",
  "border-[0.5px]",
  "border-solid",
  "border-white",
  "xl:p-0",
  "xl:border-none"
])

const benefits = cx([
  "grid-in-benefits",
  "grid",
  "grid-cols-[repeat(3,_auto)]",
  "grid-rows-[1fr]",
  "gap-x-1",
  "justify-between",
  "text-[14px]/[120%]",
  "font-bold",
  "md:justify-center",
  "md:gap-x-6",
  "xl:justify-start"
])

const benefitsItem = cx([
  "relative",
  "pl-2.5",
  "before:absolute",
  "before:top-[7px]",
  "before:left-[-8px]",
  "before:block",
  "before:w-[17px]",
  "before:rotate-90",
  "before:border",
  "before:border-solid",
  "before:border-yellow-yellow",
  "before:rounded-xl"
])

export default {
  wrapper,
  title,
  titleText,
  devices,
  device,
  devicePicture,
  deviceInfo,
  deviceInfoName,
  deviceInfoPrice,
  deviceInfoButton,
  buttonWrapper,
  button,
  devicesGrid,
  devicesGridItem,
  devicesGridItemImg,
  devicesGridItemImgActive,
  benefits,
  benefitsItem
}
