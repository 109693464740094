import { ROUTE_NAMES } from "shared/constants"

export default [
  {
    name: "Продать смартфон",
    to: ROUTE_NAMES.TRADE_IN,
    reachGoal: () => {}
  },
  {
    name: "Ещё",
    items: [
      {
        name: "Контакты",
        to: ROUTE_NAMES.CONTACTS,
        reachGoal: () => {}
      },
      {
        name: "О компании",
        to: ROUTE_NAMES.COMPANY,
        reachGoal: () => {}
      },
      {
        name: "Публичная оферта",
        to: "/ofertaPM",
        target: "_blank",
        reachGoal: () => {}
      },
      {
        name: "Реквизиты компании",
        to: ROUTE_NAMES.COMPANY_DETAILS,
        reachGoal: () => {}
      },
      {
        name: "Политика по обработке персональных данных",
        to: "/politics",
        target: "_blank",
        reachGoal: () => {}
      },
      {
        name: "Калькулятор Trade-in",
        to: ROUTE_NAMES.TRADE_IN,
        target: "_blank",
        reachGoal: () => {}
      },
      {
        name: "Как оплатить и получить",
        to: ROUTE_NAMES.PAYMENT,
        reachGoal: () => {}
      },
      {
        name: "Гарантия",
        to: ROUTE_NAMES.GUARANTEE,
        reachGoal: () => {}
      },
      {
        name: "Возврат",
        to: ROUTE_NAMES.RETURN,
        reachGoal: () => {}
      },
      {
        name: "Адреса СЦ для гарантийного ремонта",
        to: ROUTE_NAMES.GARANTIA_SERVICE,
        reachGoal: () => {}
      }
    ]
  }
]
