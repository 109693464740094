const wrapper = [
  "my-6",
  "mx-0",
  "grid",
  "md:my-10",
  "md:grid-cols-[auto_390px]",
  "md:gap-10"
]

const title = [
  "font-bold",
  "text-primary",
  "text-[21px]/[31.5px]",
  "mb-3",
  "grid",
  "grid-cols-[repeat(2,_min-content)]",
  "whitespace-nowrap",
  "gap-2",
  "md:text-[36px]/[54px]",
  "md:gap-4"
]

const titlePercent = [
  "text-[10px]/[12px]",
  "font-bold",
  "text-primary",
  "bg-magic-green-400",
  "py-1",
  "px-2",
  "rounded-2xl",
  "self-center",
  "md:text-[16px]/[19px]",
  "md:px-3"
]

const grid = [
  "grid",
  "gap-6"
]

const inputGroup = [
  "flex",
  "flex-wrap",
  "gap-3"
]

const input = [
  "flex-[0_0_100%]",
  "md:flex-[0_0_calc((100%_/_3)_-_8px)]"
]

const inputFullWidth = "!basis-full"

const inputMobileHalf = [
  "flex-[0_0_calc((100%_/_2)_-_6px)]",
  "md:flex-[0_0_calc((100%_/_3)_-_8px)]"
]

const inputTwoCol = "md:flex-[0_0_calc(66.6%_-_4px)]"

const actionTitle = [
  "font-bold",
  "text-[21px]/[31.5px]",
  "text-primary",
  "mb-3",
  "md:text-[36px]/[54px]"
]

const actionGrid = [
  "md:grid",
  "md:grid-cols-[repeat(2,_1fr)]",
  "md:items-center",
  "md:w-4/5",
  "md:gap-2"
]

const actionBtn = [
  "w-full",
  "mt-4",
  "md:py-4",
  "md:px-[26px]",
  "md:mt-0"
]

const text = [
  "mb-3",
  "rounded-lg",
  "bg-bg-main-thirdly",
  "p-4",
  "grid",
  "grid-cols-[min-content_auto]",
  "gap-4",
  "leading-[1.3]",
  "sticky",
  "top-[140px]"
]

const checkbox = [
  "flex",
  "items-center",
  "mb-2"
]

const checkboxInput = [
  "absolute",
  "z-[-1]",
  "opacity-0",
  "peer"
]

const checkboxLabel = [
  "text-secondary",
  "inline-flex",
  "items-center",
  "select-none",
  "before:inline-block",
  "before:w-[18px]",
  "before:h-[18px]",
  "before:border-2",
  "before:border-solid",
  "before:border-primary",
  "before:bg-bg-main-primary",
  "before:rounded",
  "before:mr-2",
  "before:bg-no-repeat",
  "before:bg-center",
  "peer-checked:before:bg-bg-blue-accent",
  "peer-checked:before:border-border-primary",
  "peer-checked:before:bg-[url('/images/checkmark2.svg')]"
]

export default {
  wrapper,
  title,
  titlePercent,
  grid,
  inputGroup,
  input,
  inputFullWidth,
  inputMobileHalf,
  inputTwoCol,
  actionTitle,
  actionGrid,
  actionBtn,
  text,
  checkbox,
  checkboxInput,
  checkboxLabel
}
